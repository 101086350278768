import clsx from "clsx";
import { useState } from "react";

export function AboutMe() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = e.currentTarget?.scrollTop;
    const height = e.currentTarget?.clientHeight;

    const scrollRatio = scrollTop / height;
    const scrollPercentage = scrollRatio * 100;

    if (scrollPercentage < 50) {
      setScrollPosition(scrollPercentage);
    }
  };

  return (
    <div
      className={clsx(
        "overflow-y-scroll h-full gap-4 focus:border-none pc:px-8",
        "no-scrollbar"
      )}
      onScroll={handleScroll}
    >
      <div className="z-0 absolute aspect-square pc:h-96 h-64 pc:translate-x-32 translate-x-20 pc:translate-y-32 translate-y-20 bg-iris rounded-full bottom-0 right-0" />

      <div
        className={clsx([
          {
            "flex flex-col h-full content-between bg-gradient-to-tr to-taupe-700 from-taupe-900 z-10 duration-100":
              true,
          },
        ])}
        style={{
          transform: `translate(-${scrollPosition / 2}%, ${scrollPosition / 2}%) scale(${100 - scrollPosition}%)`,
        }}
      >
        <div
          className={clsx([
            {
              "flex h-full tablet:flex-col phone:flex-col items-center justify-center gap-8 duration-300 w-full":
                true,
            },
          ])}
        >
          <div
            className={
              "flex flex-col gap-1 bg-marigold border-8 border-daffodil pc:h-72 pc:w-56 h-48 w-40 text-center items-center justify-center duration-300 rounded-lg"
            }
          >
            <img
              src={require("../assets/avatar-girl.jpeg")}
              alt="Avatar of Me!"
              className={"rounded-full h-48 w-48"}
            />
            <p className="pc:text-sm text-xs text-cosmos text-nowrap">
              *Images shown are for <br />
              illustration purposes only.
            </p>
          </div>
          <div className="w-max border-8 p-2 border-marigold">
            <h1
              className={
                "font-mono animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-taupe text-taupe font-bold pc:text-5xl text:xl"
              }
            >
              &lt;&gt; Hello World &lt;/&gt;
            </h1>
          </div>
        </div>
        <p
          className={clsx([
            {
              "w-full text-center animate-in fade-in text-taupe z-10 duration-500":
                true,
              "opacity-0": scrollPosition !== 0,
            },
          ])}
        >
          &#11206; Scroll down for introduction &#11206;
        </p>
      </div>
      <div
        className={clsx([
          {
            "flex flex-col gap-4 text-cosmos text-lg indent-8 z-10 h-full duration-300 pb-4 phone:p-2 tablet:p-2":
              true,
            "translate-y-[-10%]": scrollPosition !== 0,
          },
        ])}
      >
        <p className="">
          Hey there! 👋 I&apos;m Burcu, a frontend developer with a knack for
          turning lines of code into websites. Welcome to my corner of the
          internet, where I created as a digital playground to showcase and
          document some of the things I find relevant and fun. Every pixel,
          every line of code here speaks about my frontend journey.
        </p>
        <p>
          One of the highlights of this site is the UI library I&apos;ve
          painstakingly tried to craft. I wanted to create this UI library as a
          template for myself. With this personally-crafted UI library, I can
          start future projects without wasting time on repetitive tasks or
          relying on bulky UI libraries. Also I will add some of the interesting
          things I found out during my coding adventures.
        </p>
        <p>
          But hey, it&apos;s not all serious business here! I believe in
          infusing a little bit of fun into everything I do. That&apos;s why
          you&apos;ll find some css arts, quizes and utterly useless components
          scattered around this site, just for a good laugh. After all, who said
          coding can&apos;t be funny?
        </p>
        <p>
          So, stick around, explore, and maybe even find a little inspiration
          along the way. Whether you&apos;re a fellow developer, a curious soul,
          or just stumbled upon this site by accident, I hope you enjoy the ride
          as much as I&apos;ve enjoyed building it. Happy coding! 🚀
        </p>
      </div>
    </div>
  );
}
