import CodeBlock from "../../../components/Components/CodeBlock/CodeBlock";
import DisplayTable from "../../../components/DisplayTable";
import SectionHeader from "../../../components/SectionHeader";
import {
  paginatedFetchCode,
  paginatedFetchPropsTable,
  paginatedFetchReturnsTable,
} from "./paginatedFetchData";

export default function PaginatedFetch() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Paginated Fetch
        </p>
        <p className="text-sm font-semibold text-cosmos">
          Throughout my career and in my personal projects, I’ve often needed to
          fetch data in chunks. This seemingly simple task is crucial for many
          of my favorite components, like data tables and autocomplete features.
          To solve this once and for all, I built a custom paginated fetch
          solution. Enjoy!
        </p>
      </div>
      <SectionHeader
        title="How to Use"
        text="Here is an example use of the component:"
      />
      <CodeBlock language="javascript" code={paginatedFetchCode} />
      <DisplayTable
        propDataList={paginatedFetchPropsTable}
        text="These are the props you can give:"
        title="Props"
      />
      <DisplayTable
        propDataList={paginatedFetchReturnsTable}
        text="These are the things the hook will return"
        title="Returns"
      />
    </div>
  );
}
