import {
  Overwrite,
  UseMultipleSelectionGetSelectedItemPropsOptions,
  UseMultipleSelectionGetSelectedItemReturnValue,
} from "downshift";
import { X } from "lucide-react";
import clsx from "clsx";

export type SelectionBoxProps<T> = {
  disabled: boolean;
  index: number;
  optionToString: (option: T) => string;
  removeSelectedItem: (item: T) => void;
  selectedItemForRender: T;
  getSelectedItemProps: <Options>(
    options: UseMultipleSelectionGetSelectedItemPropsOptions<T> & Options
  ) => Omit<
    Overwrite<UseMultipleSelectionGetSelectedItemReturnValue, Options>,
    "selectedItem" | "index"
  >;
};

export function SelectionBox<T>(props: SelectionBoxProps<T>) {
  const {
    disabled,
    getSelectedItemProps,
    index,
    optionToString,
    removeSelectedItem,
    selectedItemForRender,
  } = props;

  return (
    <span
      className={clsx(
        [
          {
            "flex bg-amber-500/20 rounded-lg px-2 max-w-32 whitespace-nowrap gap-1 py-1":
              true,
            "bg-slate-500/20": disabled,
          },
        ],
        "ms-selectionbox"
      )}
      {...getSelectedItemProps({
        selectedItem: selectedItemForRender,
        index,
      })}
    >
      <p className={clsx("font-medium truncate", "ms-selectionbox-text")}>
        {optionToString(selectedItemForRender)}
      </p>
      <span
        className={clsx(
          [
            {
              "px-1 h-6 rounded-md w-6 flex items-center text-white": true,
              "cursor-pointer bg-amber-600 hover:brightness-90": !disabled,
              "bg-slate-400": disabled,
            },
          ],
          "ms-selectionbox-button"
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            removeSelectedItem(selectedItemForRender);
          }
        }}
      >
        <X size={24} strokeWidth={3} />
      </span>
    </span>
  );
}
