import clsx from "clsx";
import { AlertCircle, CircleCheck, Info, X, XCircle } from "lucide-react";
import { ToastPosition } from "./ToastList";
import { forwardRef } from "react";

export interface ToastOptions {
  className?: string;
  message?: string;
  Action?: JSX.Element;
  type: ToastType;
  Icon?: JSX.Element;
  position?: ToastPosition;
  title?: string;
}

export interface Toast extends ToastOptions {
  id: number;
}

export type ToastType = "success" | "error" | "info" | "warning";

type ToastCompProps = {
  removeToast: () => void;
  toast: Toast;
};

const iconMap: Record<ToastType, JSX.Element> = {
  success: <CircleCheck width={"36px"} height={"36px"} />,
  error: <XCircle width={"36px"} height={"36px"} />,
  info: <Info width={"36px"} height={"36px"} />,
  warning: <AlertCircle width={"36px"} height={"36px"} />,
};

const defaultColorClassMap: Record<ToastType, string> = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-blue-400",
  warning: "bg-yellow-600",
};

function DefaultAction({ onClose }: { onClose: () => void }) {
  return (
    <button
      className="flex hover:bg-white/10 rounded-full aspect-square h-full justify-center items-center"
      onClick={onClose}
    >
      <X
        className="text-white p-1 h-full text-9xl"
        width={"28px"}
        height={"28px"}
      />
    </button>
  );
}

export const Toast = forwardRef(
  (props: ToastCompProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const { removeToast, toast } = props;

    const ToastIcon = toast.Icon ?? (iconMap[toast.type] || null);

    return (
      <div
        className={clsx(
          "flex px-2 py-1 min-w-48 w-fit text-white opacity-75 hover:opacity-100 shadow-md gap-2 rounded-md fade-in-75 animate-in items-center justify-between " +
            defaultColorClassMap[toast.type],
          toast.className
        )}
        role="alert"
        ref={ref}
      >
        {ToastIcon}
        <div className="flex flex-col text-sm">
          {toast.title && (
            <p className={clsx("font-extrabold leading-none", "toast-title")}>
              {toast.title}
            </p>
          )}
          {toast.message && <p className="toast-message">{toast.message}</p>}
        </div>
        {toast.Action ?? <DefaultAction onClose={removeToast} />}
      </div>
    );
  }
);

Toast.displayName = "Toast";
