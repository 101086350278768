import CodeBlock from "../../../components/Components/CodeBlock/CodeBlock";
import DisplayTable from "../../../components/DisplayTable";
import SectionHeader from "../../../components/SectionHeader";
import WorkingDebounceExample from "./WorkingDebounceExample";
import WorkingThrottleExample from "./WorkingThrottleExample";
import {
  useDebouncedInputCode,
  useDebouncedInputProps,
  useDebouncedInputReturns,
  useThrottleCode,
  useThrottleProps,
  useThrottleReturns,
} from "./debounceAndThrottleData";

export default function DebounceAndThrottle() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Debounce & Throttle
        </p>
        <p className="text-sm font-semibold text-cosmos">
          Curious about how debounce and throttle differ? You&apos;re in the
          right place! Here, I&apos;ll break down the differences while
          introducing you to my custom useDebouncedInput and useThrottle hooks.
          Dive in to see how each one can bring smooth, efficient handling to
          your events!
        </p>
      </div>
      <SectionHeader
        title="Debounce"
        text="Debounce is perfect for situations where users provide multiple inputs, and you want to act only after they're done typing or interacting. Instead of processing every single input in real-time, debounce waits until the user pauses, treating the final input as a complete chunk. This way, you handle the end result efficiently and avoid overloading your application."
      />
      <p className="text-sm text-cosmos mt-2 font-semibold">
        Here is an example of my useDebouncedInput hook:
      </p>
      <CodeBlock language="tsx" code={useDebouncedInputCode} />
      <DisplayTable title="Props" propDataList={useDebouncedInputProps} />
      <DisplayTable title="Returns" propDataList={useDebouncedInputReturns} />
      <SectionHeader
        title="Working Example"
        text="Try typing somethings and you will see debounced value next to it."
      />
      <WorkingDebounceExample />
      <SectionHeader
        title="Throttle"
        text="Throttle is a solution for handling data that changes frequently. Instead of reacting to every single change, throttle ensures that functions are executed or data is read at controlled intervals. This prevents your system from getting overwhelmed by rapid updates and keeps performance smooth and steady, even when the data is constantly in flux."
      />
      <p className="text-sm text-cosmos mt-2 font-semibold">
        Here is an example of my useThrottle hook:
      </p>
      <CodeBlock language="tsx" code={useThrottleCode} />
      <DisplayTable title="Props" propDataList={useThrottleProps} />
      <DisplayTable title="Returns" propDataList={useThrottleReturns} />
      <WorkingThrottleExample />
    </div>
  );
}
