import clsx from "clsx";
import { NavBarItem } from "../Header";
import { NavItemSubMenu } from "./NavItemSubMenu";
import { ChevronDown } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

type NavItemProps = {
  className?: string;
  navItem: NavBarItem;
};

export function NavItem(props: NavItemProps) {
  const { className, navItem } = props;

  const location = useLocation();

  const isPageActive = navItem.menuItem.link
    ? location.pathname.includes(navItem.menuItem.link)
    : false;

  return (
    <div className="flex flex-col group/navItem gap-4">
      <div
        className={clsx(
          [
            {
              "hover:bg-marigold/80 px-6 rounded-full w-min h-min flex flex-col cursor-pointer items-center py-1 font-bold text-lg text-cosmos":
                true,
              "bg-marigold/80": isPageActive,
            },
          ],
          className
        )}
      >
        {!navItem.hasSubMenu && (
          <Link
            className="h-full w-full"
            to={navItem.menuItem.link ?? ""}
            state={{ from: location.pathname }}
          >
            <span className="flex gap-1 w-max place-items-center group-hover/link:h-64 transition-all duration-500 ease-out">
              {navItem.menuItem.text}
            </span>
          </Link>
        )}
        {navItem.hasSubMenu && (
          <div>
            <span className="flex items-center justify-between gap-1">
              {navItem.menuItem.text}
              <ChevronDown className="transition group-hover/navItem:rotate-180 delay-150 duration-300 fade-in-20" />
            </span>
          </div>
        )}
      </div>
      {navItem.hasSubMenu && !!navItem.subMenuItems?.length && (
        <NavItemSubMenu subMenuItems={navItem.subMenuItems} />
      )}
    </div>
  );
}
