import DisplayTable from "../../../components/DisplayTable";
import SectionHeader from "../../../components/SectionHeader";
import { codeBlockCode, codeBlockProps } from "./codeBlockData";
import CodeBlockComp from "../../../components/Components/CodeBlock/CodeBlock";

export default function CodeBlock() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Code Block
        </p>
        <p className="text-sm font-semibold text-cosmos">
          While building this website, I realized I needed to add code blocks to
          speed up my copy-paste abilities from this website.So, I decided to
          create my own! I used PrismJS for code highlighting to make the
          snippets easy to read. I hope these blocks help you as much as they’ve
          helped me.
        </p>
      </div>
      <div />
      <SectionHeader
        title="Usage"
        text="Here is a Code Block of how to use my Code Block :)"
      />
      <CodeBlockComp code={codeBlockCode} language="tsx" />
      <DisplayTable
        title="Props"
        text="!!! Don't forget to add PrismJS to your project before using this. !!!"
        propDataList={codeBlockProps}
      />
    </div>
  );
}
