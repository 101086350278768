import { Outlet } from "react-router-dom";
import { ComponentsNav as PCComponentsNav } from "../components/PCNavigationItems/ComponentsNav";
import { ComponentsNav as MobileComponentsNav } from "../components/MobileNavigationItems/ComponentsNav";
import { NavBarItem } from "../components/Header";

type Props = {
  subMenuItems: NavBarItem[];
};

export function PageLayoutWithNav(props: Props) {
  const { subMenuItems } = props;

  return (
    <div className="flex w-full h-full bg-lily shadow-inner">
      <PCComponentsNav subMenuItems={subMenuItems} />
      <MobileComponentsNav subMenuItems={subMenuItems} />
      <div className="pc:pl-[208px] w-full overflow-y-scroll px-8 phone:px-2 tablet:px-2 phone:pb-16 tablet:pb-16">
        <Outlet />
      </div>
    </div>
  );
}
