import { X } from "lucide-react";
import ReactDom from "react-dom";
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../views/AppLayout";
import clsx from "clsx";

interface Props extends React.PropsWithChildren {
  active: boolean;
  onClose: () => void;
}

export default function Modal({ active, children, onClose }: Props) {
  const theme = useContext(ThemeContext);

  const [isVisible, setIsVisible] = useState(false);

  const closeModal = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    if (active) {
      setIsVisible(true);
    }
  }, [active]);

  if (!active) return null;

  return ReactDom.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      data-visible={isVisible ? "true" : "false"}
      onClick={closeModal}
      className={clsx([
        {
          "fixed text-cosmos/70 z-[9999] flex h-screen w-screen items-center justify-center data-[visible=true]:opacity-100 data-[visible=true]:visible data-[visible=false]:opacity-0 data-[visible=false]:invisible transition-all duration-300 bg-cosmos/70 p-2":
            true,
          dark: theme === "dark",
        },
      ])}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col pc:w-3/4 w-full group-data-[visible=true]:opacity-100 group-data-[visible=true]:visible group-data-[visible=false]:opacity-0 group-data-[visible=false]:invisible rounded-md border-2 border-cosmos bg-lily box-shadow shadow-cosmos transition-all duration-300 "
      >
        <div className="w-full flex justify-end h-6 p-3">
          <button onClick={closeModal}>
            <X />
          </button>
        </div>
        <div className="h-full max-h-[80vh] w-full p-2 overflow-y-scroll">
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
}
