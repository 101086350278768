export enum QuizActionKind {
  INCREASEPAGE = "incremented_page",
  DECREASEPAGE = "decremented_page",
  START = "started_quiz",
  END = "ended_quiz",
  ANSWER = "answered_question",
  INITIALIZE = "initialized_quiz",
}

export type Choice = "A" | "B" | "C" | "D";

export interface QuizAction {
  type: QuizActionKind;
  payload?: Choice;
}

export interface QuizState {
  page: number;
  isActive: boolean;
  answers: Choice[];
}

export function quizReducer(state: QuizState, action: QuizAction): QuizState {
  const { type, payload } = action;

  switch (type) {
    case QuizActionKind.INCREASEPAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case QuizActionKind.DECREASEPAGE: {
      return {
        ...state,
        page: state.page - 1,
      };
    }
    case QuizActionKind.START: {
      return {
        ...state,
        isActive: true,
      };
    }
    case QuizActionKind.END: {
      return {
        ...state,
        isActive: false,
      };
    }
    case QuizActionKind.INITIALIZE: {
      return {
        ...state,
        isActive: false,
        page: 1,
        answers: [],
      };
    }
    case QuizActionKind.ANSWER: {
      if (payload) {
        const newAnswers = [...state.answers];
        if (!newAnswers[state.page - 1]) {
          newAnswers[state.page - 1] = payload;
        }
        return {
          ...state,
          answers: newAnswers,
        };
      }
      throw Error("Please provide payload for the action.");
    }
    default:
      return state;
  }
}
