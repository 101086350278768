import { useEffect } from "react";
import Prism from "prismjs";
import { CopyIcon } from "lucide-react";
import clsx from "clsx";
import { useToast } from "../Toast/ToastProvider";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-tsx";

import "prismjs/plugins/line-numbers/prism-line-numbers";
import "./prismStyle.css";

type Props = { code: string; language: string };

const CodeBlock = (props: Props) => {
  const { code, language } = props;

  const { showToast } = useToast();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    showToast({
      className: "bg-cosmos/90",
      message: "Copied To Clipboard!",
      type: "info",
      position: "bottom-left",
    });
  };

  return (
    <div
      className={clsx(
        "line-numbers",
        "flex border-2 border-cosmos/20 rounded-lg shadow-md relative"
      )}
    >
      <pre className="flex flex-col">
        <code className={`language-${language}`}>{code}</code>
      </pre>
      <button
        className="bg-cosmos/60 w-fit p-2 rounded-md absolute right-0 bottom-0 shadow-md hover:bg-cosmos/80 m-2"
        onClick={handleCopyClick}
      >
        <CopyIcon className="text-lily" />
      </button>
    </div>
  );
};

export default CodeBlock;
