import { PropTableData } from "../../../components/DisplayTable";

export const codeBlockCode = `export default function InceptionCodeBlock(){
    const codeBlockCode=\`console.log("I can't continue this inception foreveer!");\`

    return(
        <CodeBlock code={codeBlockCode} language="javascript" />
    );
}`;

export const codeBlockProps: PropTableData[] = [
  {
    name: "code",
    type: "string",
    default: "-",
    description: "Your code snippet that you want to display.",
  },
  {
    name: "language",
    type: "string ",
    default: "-",
    description:
      "The language of the code snippet. (You should add languages from PrismJS and you will be able able to use the languages in your .babelrc file)",
  },
];
