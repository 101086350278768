import { Choice } from "./quizReducer";

export type AnswerChoices = Record<Choice, string>;

type QuizQuestionData = {
  questionCode: string;
  correctAnswer: Choice;
  explanation: string;
  answerChoices: AnswerChoices;
};

export const quizData: QuizQuestionData[] = [
  {
    questionCode: `[1, 2, 3] + [4, 5, 6]`,
    correctAnswer: "D",
    explanation:
      "When + is used between arrays, JavaScript converts each array into a comma-separated string using the toString method, so [1, 2, 3] becomes '1,2,3' and [4, 5, 6] becomes '4,5,6'. It then concatenates these strings, producing '1,2,34,5,6'. This behavior occurs because the + operator defaults to string concatenation rather than numerical addition when applied to non-numeric types.",
    answerChoices: {
      A: "[[1, 2, 3], [4, 5, 6]]",
      B: "[123456]",
      C: "'123456'",
      D: "'1, 2, 34, 5, 6'",
    },
  },
  {
    questionCode: `['1', '7', '11'].map(parseInt);`,
    correctAnswer: "C",
    explanation:
      "The map method passes three arguments to its callback: the element, its index, and the array itself. parseInt takes two arguments: the string to parse and the radix (base) for conversion. So, parseInt('1', 0) interprets '1' in base 10, yielding 1. However, parseInt('7', 1) uses 1 as the radix, which is invalid, returning NaN. Lastly, parseInt('11', 2) interprets '11' as binary, yielding 3. To avoid this, use an arrow function to only pass the element: ['1', '7', '11'].map(str => parseInt(str)), which correctly returns [1, 7, 11].",
    answerChoices: {
      A: "undefined",
      B: "[1 , 7 , 11]",
      C: "[1, NaN, 3]",
      D: "[1, 2, 3]",
    },
  },
  {
    questionCode: `3 > 2 > 1`,
    correctAnswer: "D",
    explanation:
      "First Comparison (3 > 2): This evaluates to true. Second Comparison (true > 1): Here, true is implicitly converted to 1, making the comparison 1 > 1, which is false.",
    answerChoices: {
      A: "SyntaxError",
      B: "NaN",
      C: "true",
      D: "false",
    },
  },
  {
    questionCode: `[] == ![]`,
    correctAnswer: "A",
    explanation: `An empty array [] is a truthy value. Negating it with ! converts it to false. The expression becomes [] == false. false is coerced to 0.An empty array [] is coerced to an empty string "", and then to 0 when compared with a number. So, 0 == 0 results in true.`,
    answerChoices: {
      A: "true",
      B: "false",
      C: "SyntaxError",
      D: "undefined",
    },
  },
  {
    questionCode: `NaN === NaN > (null == undefined)`,
    correctAnswer: "B",
    explanation:
      "NaN is a special value that is not equal to any other value, including itself. Therefore, NaN === NaN is false. Second comparison is true in JavaScript because null and undefined are loosely equal (using ==). false > true is false because 0 is not bigger than 1. ",
    answerChoices: {
      A: "true",
      B: "false",
      C: "SyntaxError",
      D: "¯\\_(ツ)_/¯",
    },
  },
  {
    questionCode: `018 - 017`,
    correctAnswer: "B",
    explanation:
      "In JavaScript and many other programming languages, when you have a number prefixed with 0 (like 018 and 017), it is interpreted as an octal (base 8) number literal if it contains only the digits 0-7. Here's why 018 - 017 equals 3",
    answerChoices: {
      A: "1",
      B: "3",
      C: "5",
      D: "Nan",
    },
  },
  {
    questionCode: `"" - - ""`,
    correctAnswer: "A",
    explanation: `JavaScript attempts to coerce the empty strings "" into numbers when using the subtraction operator -. An empty string "" coerces to 0 when converted to a number. So the expression becomes this: 0 - - 0 which is equal to 0.`,
    answerChoices: {
      A: "0",
      B: "1",
      C: "Nan",
      D: "undefined",
    },
  },
  {
    questionCode: `0/0`,
    correctAnswer: "C",
    explanation: `In JavaScript and many programming languages, 0/0 (zero divided by zero) results in NaN, which stands for "Not a Number". This result occurs because dividing zero by zero does not yield a defined numerical value.`,
    answerChoices: {
      A: "1",
      B: "0",
      C: "NaN",
      D: "Infinity",
    },
  },
  {
    questionCode: `typeof NaN`,
    correctAnswer: "D",
    explanation: `NaN is a numeric value according to the language's type system, despite its specialized behavior as "Not a Number" in arithmetic operations.`,
    answerChoices: {
      A: "NaN",
      B: "undefined",
      C: "string",
      D: "number",
    },
  },
  {
    questionCode: `+!!NaN && !!2`,
    correctAnswer: "D",
    explanation:
      "NaN is a falsy value. !!NaN then converts true back to false due to the double negation, making it false. !!2 converts 2 to true because 2 is truthy, and then true to true again due to the double negation. Since +!!NaN is 0 (falsy), the entire expression +!!NaN && !!2 evaluates to 0 because the && operator returns the first falsy operand (0).",
    answerChoices: {
      A: "NaN",
      B: "undefined",
      C: "2",
      D: "0",
    },
  },
];
