import React, { useRef, useState } from "react";
import Button from "../components/Button";
import PandaMascot from "./PandaMascot/PandaMascot";
import emailjs from "@emailjs/browser";
import { z } from "zod";
import { useToast } from "../components/Components/Toast/ToastProvider";

const FormData = z.object({
  username: z.string().min(2, "We need more than just initials for your name."),
  email: z
    .string()
    .email("Email fail! Please check your typing skills.")
    .min(5, "Mini email detected! Please provide a complete one."),
  message: z
    .string()
    .min(5, "Your message was short and sweet, but we need more info."),
});

export function ContactMe() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const { showToast } = useToast();
  const form = useRef<HTMLFormElement>(null);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const mousePos = { x: e.clientX, y: e.clientY };
    setMousePosition(mousePos);
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      const username = e.currentTarget["user_name"].value;
      const email = e.currentTarget["user_email"].value;
      const message = e.currentTarget["message"].value;

      const result = FormData.safeParse({ username, email, message });
      if (!result.success) {
        result.error.issues.map((i) => {
          showToast({
            title: "Oops!",
            message: i.message,
            type: "error",
          });
        });
      } else {
        emailjs
          .sendForm("service_05fywxw", "template_7bwlayy", form.current, {
            publicKey: "Cq89CQkzE3LbDVSP9",
          })
          .then(
            () => {
              showToast({
                title: "Bravo!",
                message: "Your message is en route!",
                type: "success",
              });
            },
            (error) => {
              /* eslint no-console: 0*/
              console.log("FAILED...", error.text);
            }
          );
      }
    }
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      className="relative w-full h-full flex flex-col justify-center items-center px-8 phone:px-2 tablet:px-2"
    >
      <div className="w-full flex h-full justify-center phone:scale-50 tablet:scale-50 scale-90 phone:-mt-24 tablet:-mt-28">
        <PandaMascot mousePosition={mousePosition} />
      </div>
      <form
        className="h-full pc:w-2/3 bg-lily rounded-md z-20 -mt-60 box-shadow border-2 shadow-cosmos border-cosmos overflow-y-scroll pl-3 pt-3"
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="w-full flex phone:flex-col tablet:flex-col gap-4">
          <div className="flex flex-col w-full">
            <p className="text-cosmos/80 font-semibold text-lg">
              Name and title:
            </p>
            <input
              className="h-11 w-full bg-lily rounded-lg box-border border-2 border-cosmos/30 focus:border-rose outline-none p-3 transition-all shadow-md"
              type="text"
              name="user_name"
              onChange={() => {
                console.log("asd");
              }}
              placeholder="How should I call you?"
            />
          </div>
          <div className="flex flex-col w-full">
            <p className="text-cosmos/80 font-semibold text-lg">Email:</p>
            <input
              className="h-11 w-full bg-lily rounded-lg box-border border-2 border-cosmos/30 focus:border-rose outline-none p-3 transition-all shadow-md"
              type="email"
              name="user_email"
              onChange={() => {
                console.log("asd");
              }}
              placeholder="How can I reach you?"
            />
          </div>
        </div>
        <div className="flex flex-col w-full pt-2">
          <p className="text-cosmos/80 font-semibold text-lg">Message:</p>
          <textarea
            className="h-1/2 w-full bg-lily rounded-lg box-border border-2 border-cosmos/30 focus:border-rose outline-none p-3 transition-all shadow-md"
            onChange={() => {
              console.log("asd");
            }}
            placeholder="What's on your mind?"
            name="message"
            rows={4}
            cols={50}
          />
        </div>
        <div className="w-full flex items-center justify-center py-4">
          <Button
            className="bg-rose text-white w-1/3"
            type="submit"
            value="Send"
          >
            Send
          </Button>
        </div>
      </form>
      <p className="p-2 text-xs text-cosmos">
        * I just wanted to share that this panda made only by using css. It was
        the first time I did something like this. Hope you enjoy!
      </p>
    </div>
  );
}
