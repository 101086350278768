import { useRef } from "react";

type Props = {
  duration?: number;
};

export function useThrottle(props?: Props) {
  const duration = props?.duration ?? 300;
  const throttleInProgress = useRef<boolean>(false);

  const throttle = (fn: () => void) => {
    if (throttleInProgress.current) {
      return;
    }

    throttleInProgress.current = true;

    setTimeout(() => {
      fn();

      throttleInProgress.current = false;
    }, duration);
  };

  return throttle;
}
