import OtherExamples from "./OtherExamples";
import PropsAndClasses from "./PropsAndClasses";
import Usage from "./Usage";
import WorkingExamples from "./WorkingExamples";

export function MultiselectAutocomplete() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Multiselect Autocomplete
        </p>
        <p className="text-sm font-semibold text-cosmos">
          I’ve had quite the journey with autocompletes. As a junior developer,
          building an autocomplete was one of my biggest challenges. It became a
          cornerstone of my learning experience. Now, I’ve gone a step further
          and crafted a multiselect autocomplete component, something I’ve found
          missing in most UI libraries. Leveraging the Downshift library for
          accessibility, this custom tool is ready to handle multiple selections
          with ease. Please enjoy the fruits of my long history with
          autocompletes!
        </p>
        <div className="flex gap-2 items-center">
          <p className="text-sm font-semibold text-cosmos">
            You can learn more on Downshift here:
          </p>
          <a
            className="text-taupe hover:text-iris underline"
            href="https://www.downshift-js.com/"
          >
            https://www.downshift-js.com/
          </a>
        </div>
      </div>
      <Usage />
      <WorkingExamples />
      <PropsAndClasses />
      <OtherExamples />
    </div>
  );
}
