import clsx from "clsx";
import { AnswerChoices } from "../quizData";
import { Choice } from "../quizReducer";

type AnswersProps = {
  answerChoices: AnswerChoices;
  answerQuestion: (answer: Choice) => void;
  correctAnswer: Choice;
  userAnswer: Choice;
};

type AnswerProps = {
  isCorrectAnswer: boolean;
  isQuestionAnswered: boolean;
  isUserAnswer: boolean;
  onClick: () => void;
  text: string;
};

function Answer(props: AnswerProps) {
  const { isCorrectAnswer, isQuestionAnswered, isUserAnswer, onClick, text } =
    props;

  return (
    <button
      className={clsx([
        {
          "border-2 border-taupe/80 p-4 w-full rounded-md shadow-md": true,
          "bg-lily hover:bg-taupe/10":
            !isQuestionAnswered ||
            (isQuestionAnswered && !isCorrectAnswer && !isUserAnswer),
          "bg-rose/60 hover:bg-rose/70":
            isQuestionAnswered && !isCorrectAnswer && isUserAnswer,
          "bg-tulip/60 hover:bg-tulip/70":
            isQuestionAnswered && isCorrectAnswer,
        },
      ])}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default function Answers(props: AnswersProps) {
  const { answerChoices, answerQuestion, correctAnswer, userAnswer } = props;

  return (
    <div className="flex flex-col gap-4 text-cosmos/70">
      <div className="flex phone:flex-col tablet:flex-col gap-4">
        <Answer
          isCorrectAnswer={correctAnswer === "A"}
          isQuestionAnswered={!!userAnswer}
          isUserAnswer={userAnswer === "A"}
          onClick={() => answerQuestion("A")}
          text={answerChoices["A"]}
        />
        <Answer
          isCorrectAnswer={correctAnswer === "B"}
          isQuestionAnswered={!!userAnswer}
          isUserAnswer={userAnswer === "B"}
          onClick={() => answerQuestion("B")}
          text={answerChoices["B"]}
        />
      </div>
      <div className="flex phone:flex-col tablet:flex-col gap-4">
        <Answer
          isCorrectAnswer={correctAnswer === "C"}
          isQuestionAnswered={!!userAnswer}
          isUserAnswer={userAnswer === "C"}
          onClick={() => answerQuestion("C")}
          text={answerChoices["C"]}
        />
        <Answer
          isCorrectAnswer={correctAnswer === "D"}
          isQuestionAnswered={!!userAnswer}
          isUserAnswer={userAnswer === "D"}
          onClick={() => answerQuestion("D")}
          text={answerChoices["D"]}
        />
      </div>
    </div>
  );
}
