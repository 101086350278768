import CodeBlock from "../../../components/Components/CodeBlock/CodeBlock";
import DisplayTable from "../../../components/DisplayTable";
import SectionHeader from "../../../components/SectionHeader";
import {
  providerProps,
  showFunctionProps,
  toastProviderCode,
  useToastCode,
} from "./toastUsageExplanationData";

export default function Usage() {
  return (
    <>
      <SectionHeader
        title="Usage"
        text={
          "You should wrap your component with provider to access useToast functions."
        }
      />
      <CodeBlock language="markup" code={toastProviderCode} />
      <DisplayTable title="Provider Props" propDataList={providerProps} />
      <p className="text-sm text-cosmos mt-2 font-semibold my-4">
        Then you can use it on any child component like this:
      </p>
      <CodeBlock language="tsx" code={useToastCode} />
      <DisplayTable
        title="Show Function Props"
        propDataList={showFunctionProps}
      />
    </>
  );
}
