import { PropTableData } from "../../../components/DisplayTable";

export const paginatedFetchCode = `  //Input Triggered Infinite Fetch Hook
  const {
    isLoading,
    results,
    fetchNextPage,
    fetchPrevPage,
  } = usePaginatedFetch({
    fetchQuery: useCallback( // useCallback to prevent unnecessary rerender
      (page: number, controller: AbortController) =>
      // I used showToast function from my toast component for onError
        searchByInput(inputValue, page, controller, onError), 
      [inputValue]
    ),
    dependencies: [inputValue], // To trigger refetch when input changes
  });`;

export const paginatedFetchPropsTable: PropTableData[] = [
  {
    name: "fetchQuery",
    type: "(page: number, controller: AbortController ) => \r\nPromise<PaginatedFetchResult<T>>",
    default: "-",
    description:
      "Your custom query that will take page number and AbortController to abort when needed and return query result.",
  },
  {
    name: "options",
    type: "PaginatedFetchOptions: { enabled: boolean }",
    default: "{ enabled: true }",
    description: "To prevent auto fetching first page when needed.",
  },
  {
    name: "dependencies",
    type: "React.DependencyList",
    default: "-",
    description:
      "First page auto fetch dependency list. In the example, when input changes all results will change and first page will be fetched again with current input.",
  },
];

export const paginatedFetchReturnsTable: PropTableData[] = [
  {
    name: "isLoading",
    type: "boolean",
    default: "false",
    description: "Loading state of the fetch function.",
  },
  {
    name: "results",
    type: "T[]",
    default: "[]",
    description: "Fetch result array.",
  },
  {
    name: "fetchNextPage",
    type: "() => Promise<void>",
    default: "-",
    description: "A function to fetch next page (as in the name :).",
  },
  {
    name: "fetchPrevPage",
    type: "() => Promise<void>",
    default: "-",
    description: "A function to fetch previous page (as in the name :).",
  },
];
