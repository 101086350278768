import { useState } from "react";
import { useDebouncedInput } from "../../../utils/useDebouncedInput";

export default function WorkingDebounceExample() {
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebouncedInput(inputValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };
  return (
    <div className="w-full flex items-center justify-center">
      <div className="flex phone:flex-col tablet:flex-col gap-4 p-4 w-2/3 items-center justify-center">
        <textarea
          className="h-24 pc:w-1/2 phone:w-full tablet:w-full resize-none bg-lily rounded-lg box-border border-2 border-cosmos/50 focus:border-marigold outline-none p-2 transition-all shadow-md"
          placeholder="Input Text"
          value={inputValue}
          onChange={(e) => handleInputChange(e)}
        />
        <div className="h-24 pc:w-1/2 phone:w-full tablet:w-full overflow-x-hidden overflow-y-scroll border-2 border-taupe/40 box-shadow rounded-lg p-1 shadow-md flex flex-col items-center gap-2 text-cosmos/70 text-lg shadow-taupe/40">
          <p className="text-taupe/60 font-extrabold whitespace-nowrap">
            Debounced Value:
          </p>
          <p className="text-cosmos/70 font-semibold whitespace-normal break-all">
            {debouncedInputValue}
          </p>
        </div>
      </div>
    </div>
  );
}
