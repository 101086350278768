import Ghost from "./Ghost";

export default function FunArt() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">Fun Art</p>
        <p className="text-sm font-semibold text-cosmos">
          Welcome to the Fun Art page! Here, I&apos;ve blended JavaScript&apos;s
          functionality with CSS&apos;s creativity to create some entertaining
          visual art. Whether you&apos;re looking for a good laugh or just a bit
          of light-hearted fun, I hope these creations bring a smile to your
          face. Have fun!
        </p>
      </div>
      <div className="flex items-center justify-center w-full h-full">
        <Ghost />
      </div>
      <div />
    </div>
  );
}
