import clsx from "clsx";
import { MultiSelect } from "../../../components/Components/Multiselect/Multiselect";
import { useToast } from "../../../components/Components/Toast/ToastProvider";
import SectionHeader from "../../../components/SectionHeader";
import { Option, searchByInput } from "./searchQuery";
import "./coloredMultiselectStyle.css";
import { Triangle } from "lucide-react";

const ColoredMultiselectStyle = {};

export default function OtherExamples() {
  const { showToast } = useToast();

  const handleError = (errorMessage: string) => {
    showToast({ message: errorMessage, title: "Error", type: "error" });
  };

  return (
    <div className="flex flex-col gap-4">
      <SectionHeader title="Other Examples" />
      <div
        className="flex gap-4 phone:flex-col tablet:flex-col items-center justify-center"
        style={ColoredMultiselectStyle}
      >
        <MultiSelect
          className="w-1/3 phone:w-full tablet:w-full"
          disabled
          optionToKey={(option: Option) => option.id.toString()}
          optionToString={(option) => option.name}
          getOptionsFn={(searchInput, page, controller) =>
            searchByInput(searchInput, page, controller, handleError)
          }
          placeHolderText="You can't search!"
        />
        <MultiSelect
          className={clsx(
            "w-1/3 phone:w-full tablet:w-full",
            "colored-multiselect"
          )}
          highlightFirstItem={false}
          optionToKey={(option: Option) => option.id.toString()}
          optionToString={(option) => option.name}
          getOptionsFn={(searchInput, page, controller) =>
            searchByInput(searchInput, page, controller, handleError)
          }
          openDropdownIcon={
            <Triangle
              className={clsx([
                {
                  "rotate-180 fill-green-600": true,
                },
              ])}
              strokeWidth={0}
              size={18}
            />
          }
          clearOnSelect={false}
          placeHolderText="Colored but same!"
        />
      </div>
      <SectionHeader
        className="my-8"
        title="DISCLAIMER!!!"
        text="This project is open-source, crafted with the goal of helping fellow developers. Instead of just importing and using it as-is, I encourage you to dive into the code. Every property and feature is there to make it easier for you to understand and build upon. Feel free to clone my code, tweak it, and add your own features. I’m excited to see how you make it your own!"
      />
    </div>
  );
}
