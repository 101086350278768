import clsx from "clsx";
import { UseComboboxReturnValue } from "downshift";
import { useIntersectionObserver } from "../../../utils/useIntersectionObserver";

type PopoverProps<T> = {
  comboboxProps: UseComboboxReturnValue<T>;
  highlightFirstItem: boolean;
  highlightSearchText: boolean;
  fetchNextPage: () => Promise<void>;
  inputValue: string;
  isLoading: boolean;
  items: T[];
  loadingText: string;
  noDataText: string;
  optionToKey: (option: T) => string;
  optionToString: (option: T) => string;
  renderOption?: (
    option: T,
    isSelected: boolean,
    inputText: string
  ) => JSX.Element;
  selectedItems: T[];
};

//Format text to get search text bold
function getFormattedText(
  highlightSearchText: boolean,
  inputValue: string,
  text: string
) {
  const trimmedinput = inputValue.trim();
  // Remove whitespace to prevent bugs according to backend
  const boldIndex = text
    .toLocaleLowerCase()
    .indexOf(trimmedinput.toLowerCase());

  if (
    text.toLocaleLowerCase().includes(trimmedinput.toLocaleLowerCase()) &&
    highlightSearchText
  ) {
    return (
      <span>
        {text.substring(0, boldIndex)}
        <span className="font-extrabold">
          {text.substring(boldIndex, boldIndex + trimmedinput.length)}
        </span>
        {text.substring(boldIndex + trimmedinput.length)}
      </span>
    );
  } else {
    return <span>{text}</span>;
  }
}

// Empty popover text indicating query state
function getEmptyPopoverText(
  isLoading: boolean,
  loadingText: string,
  noDataText: string
) {
  if (isLoading) {
    return <span>{loadingText}</span>;
  }
  return <span>{noDataText}</span>;
}

const getDefaultRenderOption = <T,>(
  option: T,
  isSelected: boolean,
  inputText: string,
  optionToString: (option: T) => string,
  highlightSearchText: boolean
) => {
  return (
    <div className="flex items-center gap-4">
      <input
        className={clsx("size-4 accent-amber-600", "ms-listitem-checkbox")}
        type="checkbox"
        checked={isSelected}
        readOnly
      />
      <p>
        {getFormattedText(
          highlightSearchText,
          inputText,
          optionToString(option)
        )}
      </p>
    </div>
  );
};

export function Popover<T>(props: PopoverProps<T>) {
  const {
    comboboxProps,
    fetchNextPage,
    highlightFirstItem,
    highlightSearchText,
    inputValue,
    isLoading,
    items,
    loadingText,
    noDataText,
    optionToKey,
    optionToString,
    renderOption,
    selectedItems,
  } = props;

  const { isOpen, getMenuProps, highlightedIndex, getItemProps } =
    comboboxProps;
  // To fetch when last list item intersects
  const ref = useIntersectionObserver(fetchNextPage);

  return (
    <ul
      className={clsx(
        "relative inline-block z-50 w-full border rounded-md bg-white font-medium slide-in-from-top-2 transition overflow-y-scroll my-2",
        {
          ["animate-in fade-in-0 max-h-80 opacity-100"]: isOpen,
          ["animate-out fade-out-0 max-h-0 opacity-0"]: !isOpen,
        },
        "ms-popover"
      )}
      {...getMenuProps()}
    >
      {isOpen &&
        (items.length > 0 ? (
          items.map((item, index) => {
            return (
              <li
                className={clsx(
                  highlightedIndex === index &&
                    highlightFirstItem &&
                    "bg-amber-500/20 overflow-hidden",
                  "py-2 px-3 flex h-16 gap-2 items-center border-y first:border-t-0 last:border-b-0 cursor-pointer",
                  "ms-listitem"
                )}
                key={optionToKey(item)}
                {...getItemProps({ item, index })}
              >
                {renderOption
                  ? renderOption(item, selectedItems.includes(item), inputValue)
                  : getDefaultRenderOption(
                      item,
                      selectedItems.includes(item),
                      inputValue,
                      optionToString,
                      highlightSearchText
                    )}
              </li>
            );
          })
        ) : (
          <li
            className={clsx(
              "py-2 px-3 flex h-16 items-center border rounded-2xl",
              "ms-empty-popover-text"
            )}
          >
            {getEmptyPopoverText(isLoading, loadingText, noDataText)}
          </li>
        ))}
      <div
        className={clsx(
          "rounded-b-2xl mt-[-6px] opacity-0",
          {
            ["opacity-100"]: isLoading,
          },
          "ms-linear-progressbar"
        )}
        ref={ref}
      >
        <div className="h-1.5 w-full bg-amber-600/20 overflow-hidden">
          <div className="animate-progress w-full h-full bg-amber-600/60 origin-left-right"></div>
        </div>
      </div>
    </ul>
  );
}
