import clsx from "clsx";

export type PropTableData = {
  name: string;
  type?: string;
  default?: string;
  description: string;
};

type Props = {
  className?: string;
  propDataList: PropTableData[];
  title?: string;
  text?: string;
  type?: "props" | "other";
};

export default function DisplayTable(props: Props) {
  const { className, text, title, propDataList, type = "props" } = props;

  return (
    <div
      className={clsx("gap-2 w-full overflow-x-scroll text-cosmos", className)}
    >
      <div>
        <p className="text-lg font-extrabold text-taupe opacity-60">{title}</p>
        <div className="w-full h-[2px] bg-taupe opacity-40" />
      </div>
      {text && <p className="text-sm text-cosmos mt-2 font-semibold">{text}</p>}
      <table className="table-auto min-w-full text-left text-sm font-light">
        <thead className="border-b border-cosmos/20 font-medium max-h-12">
          <tr>
            <th className="px-1 py-4 text-center">Name</th>
            {type === "props" && (
              <>
                <th className="px-1 py-4 text-center">Type</th>
                <th className="px-1 py-4 text-center">Default</th>
              </>
            )}
            <th className="px-1 py-4 max-w-1/4 text-center">Description</th>
          </tr>
        </thead>
        <tbody>
          {propDataList.map((pd) => (
            <tr key={pd.name} className="border-b border-cosmos/20 max-h-12">
              <td className="px-1 py-4 text-center">{pd.name}</td>
              {type === "props" && (
                <>
                  <td className="px-1 py-4 text-center">{pd.type}</td>
                  <td className="px-1 py-4 text-center">{pd.default}</td>
                </>
              )}
              <td className="px-1 py-4 max-w-1/4 overflow-y-scroll text-center flex max-h-24 justify-center">
                {pd.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
