import clsx from "clsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { Header } from "../components/Header";
import ToastProvider from "../components/Components/Toast/ToastProvider";

export type ThemeOptions = "light" | "dark";

export const ThemeContext = createContext<ThemeOptions>("light");

export function AppLayout() {
  const [theme, setTheme] = useState<ThemeOptions>("light");

  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(location.pathname, {});
  }, []);

  const changeTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  const isLandingPage = pathname === "/";
  const isFirstLanding = isLandingPage && state?.from === undefined;

  return (
    <>
      <ThemeContext.Provider value={theme}>
        <Header changeTheme={changeTheme} theme={theme} />
        <ToastProvider defaultPosition="top-right">
          <div
            className={clsx(
              [
                {
                  "rounded-3xl h-12 w-[700px] pc:top-10 top-8 pc:right-40 tablet:right-8 phone:right-2 absolute bg-daisy box-shadow shadow-cosmos border-2 border-cosmos z-10 duration-1000 overflow-hidden will-change-transform":
                    true,
                  "animate-growing": isFirstLanding,
                  "pt-16 h-[90%] pc:w-[calc(100%-160px)] pc:-translate-y-2 pc:translate-x-20 tablet:w-[calc(100%-64px)] phone:w-[calc(100%-16px)] tablet:animate-in tablet:slide-in-from-bottom phone:animate-in phone:slide-in-from-bottom":
                    !isLandingPage,
                  "tablet:hidden phone:hidden": isLandingPage,
                },
              ],
              theme
            )}
          >
            <Outlet />
          </div>
        </ToastProvider>
      </ThemeContext.Provider>
    </>
  );
}
