import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

type Props = {
  className?: string;
  mousePosition: MousePosition;
};

type MousePosition = {
  x: number;
  y: number;
};

export default function PandaMascot(props: Props) {
  const { className, mousePosition } = props;
  const leftEyeRef = useRef<HTMLDivElement>(null);
  const rightEyeRef = useRef<HTMLDivElement>(null);

  const [leftAngle, setLeftAngle] = useState(140);
  const [rightAngle, setRightAngle] = useState(220);

  useEffect(() => {
    if (leftEyeRef.current) {
      const divPos = {
        x: leftEyeRef.current.getBoundingClientRect().x,
        y: leftEyeRef.current.getBoundingClientRect().y,
      };
      const vector = {
        x: mousePosition.x - divPos.x - 45,
        y: mousePosition.y - divPos.y - 45,
      };
      const angle = (Math.atan2(vector.y, vector.x * -1) * 180) / Math.PI + 180;
      setLeftAngle(angle);
    }

    if (rightEyeRef.current) {
      const divPos = {
        x: rightEyeRef.current.getBoundingClientRect().x,
        y: rightEyeRef.current.getBoundingClientRect().y,
      };
      const vector = {
        x: mousePosition.x - divPos.x - 45,
        y: mousePosition.y - divPos.y - 45,
      };
      const angle = (Math.atan2(vector.y, vector.x * -1) * 180) / Math.PI + 180;
      setRightAngle(angle);
    }
  }, [mousePosition]);

  return (
    <div className={clsx("flex flex-col justify-center", className)}>
      {/*Ears Start */}
      <div className="flex justify-between w-[450px]">
        <div className="h-32 w-32 rounded-full bg-black"></div>
        <div className="h-32 w-32 rounded-full bg-black"></div>
      </div>
      {/*Ears End */}
      {/*Face Start */}
      <div className="flex justify-center w-full h-full z-10 -translate-y-32">
        <div className="flex flex-col gap-2 bg-white w-[420px] h-[370px] rounded-[50%] border-8 border-black p-[70px] items-center ">
          {/*Eyes Start */}
          <div className="flex w-full z-10 px-2">
            <div className="w-full flex justify-between">
              <div
                className="relative h-[80px] w-24 rounded-[50%] bg-black flex p-4 rotate-[140deg]"
                style={{ justifyContent: "end" }}
              >
                <div className="absolute w-12 bg-black h-0 bottom-4 z-20 animate-pandaBlink" />
                <div
                  ref={leftEyeRef}
                  className="relative h-12 w-12 bg-white rounded-[50%] -rotate-[140deg] z-0"
                  style={{
                    transform: `rotate(-${leftAngle + 180}deg)`,
                  }}
                >
                  <div className="absolute h-7 w-7 bg-black rounded-[50%] top-4 left-4" />
                </div>
              </div>
              <div
                className="relative h-[80px] w-24 rounded-[50%] bg-black flex p-4 rotate-[220deg]"
                style={{ justifyContent: "start" }}
              >
                <div className="absolute w-12 bg-black h-0 bottom-4 z-20 animate-pandaBlink" />
                <div
                  ref={rightEyeRef}
                  className="relative h-12 w-12 bg-white rounded-[50%] -rotate-[220deg]"
                  style={{
                    transform: `rotate(-${rightAngle - 40}deg)`,
                  }}
                >
                  <div className="absolute h-7 w-7 bg-black rounded-[50%] top-[1.1rem] left-3" />
                </div>
              </div>
            </div>
          </div>
          {/*Eyes End */}
          {/*Blush Start */}
          <div className="w-full flex justify-between h-10 -translate-y-1/4">
            <div className="h-7 w-12 rounded-[50%] bg-red-100 flex p-2" />
            <div className="h-7 w-12 rounded-[50%] bg-red-100 flex p-2" />
          </div>
          {/*Blush End */}
          {/*Nose Start */}
          <div className="h-[55px] w-14 rounded-[50%] bg-black flex p-2 -translate-y-full z-10" />
          {/*Nose End */}
          {/*Mouth Start */}
          <div className="flex w-36 h-36 justify-center items-center -translate-y-14">
            <div className="w-12 h-12 bg-black rounded-full">
              <div className="w-12 h-12 bg-white rounded-full -translate-x-1 -translate-y-1" />
            </div>
            <div className="w-12 h-12 bg-black rounded-full -translate-x-1">
              <div className="w-12 h-12 bg-white rounded-full translate-x-1 -translate-y-1" />
            </div>
          </div>
          {/*Mouth End */}
        </div>
      </div>
      {/*Face End */}
    </div>
  );
}
