import clsx from "clsx";
import { NavBarItem } from "../Header";
import { NavItem } from "./NavItem";

type NavItemSubMenuProps = {
  closeDrawer: () => void;
  subMenuItems: NavBarItem[];
  isExpanded: boolean;
};

export function NavItemSubMenu(props: NavItemSubMenuProps) {
  const { closeDrawer, subMenuItems, isExpanded } = props;
  return (
    <>
      <div
        className={clsx([
          {
            "h-0 duration-300": !isExpanded,
            "h-[10.5rem] duration-300": isExpanded,
          },
        ])}
      >
        {isExpanded &&
          subMenuItems.map((subMenuItem: NavBarItem) => {
            return (
              <NavItem
                className="bg-marigold"
                onClick={undefined}
                isExpanded={false}
                closeDrawer={closeDrawer}
                key={subMenuItem.menuItem.text}
                navItem={{ menuItem: subMenuItem.menuItem, hasSubMenu: false }}
              />
            );
          })}
      </div>
    </>
  );
}
