import { PropTableData } from "../../../components/DisplayTable";

export const multiselectCode = `const handleError = (errorMessage: string) => {
    // Some code here
};

return (
    <MultiSelect
        getOptionsFn={(searchInput, page, controller) =>
            searchByInput(searchInput, page, controller, handleError)
        }
        onChange={(selectedOptions: Option[]) => {
            // Your custom onchange function
        }}
        optionToKey={(option) => option.id.toString()}
        optionToString={(option) => option.name}
    />
);`;

export const multiselectProps: PropTableData[] = [
  {
    name: "className",
    type: "string",
    default: '""',
    description: "Optional. For your tailwind or other classes.",
  },
  {
    name: "clearSelectedOnBackspace",
    type: "boolean",
    default: "true",
    description:
      "If true, when input doesn't have any text to delete, backspace will delete selection boxes.",
  },
  {
    name: "clearOnSelect",
    type: "boolean",
    default: "true",
    description:
      "If enabled, when a value selected from dropdown, input text will be cleared.",
  },
  {
    name: "disabled",
    type: "boolean",
    default: "false",
    description: "If true, component will be rendered disabled.",
  },
  {
    name: "getOptionsFn",
    type: `(searchInput: string,
    page: number,
    controller: AbortController
  ) => Promise<PaginatedFetchResult<T>>`,
    default: "-",
    description:
      "This function allows you to make backend calls with a debounced search input, sending filtered GET requests efficiently. It provides a page number for pagination and a controller to abort unwanted requests, ensuring you only get the results you need. The function waits for the paginated fetch result.",
  },
  {
    name: "highlightFirstItem",
    type: "boolean",
    default: "true",
    description:
      "If true, the first item in the list will be highlighted when the dropdown opens.",
  },
  {
    name: "highlightSearchText",
    type: "boolean",
    default: "true",
    description:
      "If enabled, matching parts of the searched property and the input text will be displayed in bold, just like in autocomplete.",
  },
  {
    name: "loadingText",
    type: "string",
    default: "Loading...",
    description: "You can customize dropdown loading text with this property.",
  },
  {
    name: "maxSelectionBoxCount",
    type: "number",
    default: "2",
    description:
      "Maximum displayable selection box count. Rest will be rendered as '+X' next to boxes.",
  },
  {
    name: "noDataText",
    type: "string",
    default: "No Data Found",
    description:
      "You can customize dropdown text shown when no data found with current search input.",
  },
  {
    name: "onChange",
    type: "(selectedOptions: T[]) => void",
    default: "-",
    description: "Your custom onChange function to control the component.",
  },
  {
    name: "openDropdownIcon",
    type: "JSX.Element",
    default: "Lucide ChevronDown Icon",
    description: "Open dropdown icon next to input.",
  },
  {
    name: "openOnFocus",
    type: "boolean",
    default: "true",
    description: "If enabled, dropdown opens on input focus.",
  },
  {
    name: "optionToKey",
    type: "(option: T) => string",
    default: "-",
    description: "Your identifier for your fetch result type.",
  },
  {
    name: "optionToString",
    type: "(option: T) => string",
    default: "-",
    description:
      "Your search property for your fetch result type. If highlighSearchText enabled, this value will be highlighted.",
  },
  {
    name: "placeHolderText",
    type: "string",
    default: '""',
    description: "Your custom input placeholder.",
  },
  {
    name: "renderOption",
    type: `(option: T,
    isSelected: boolean,
    inputText: string
  ) => JSX.Element`,
    default: "Your search property text with checkbox.",
    description:
      "You can customize how your list items will be shown. You can get option, isSelected and inputText to highlight the part of the text that matches the user's input.",
  },
];

export const multiselectClasses: PropTableData[] = [
  {
    name: ".ms-root",
    description:
      "This is the highest-level component in the multiselect. It encapsulates everything within the multiselect.",
  },
  {
    name: ".ms-input-container",
    description: "Contains everything except the popover component.",
  },
  {
    name: ".ms-selection-overflow-text",
    description:
      "Text displayed when selection count exceeds maxSelectionBoxCount.",
  },
  {
    name: ".ms-selectionbox",
    description:
      "Selection box appears when a selection is made (if total selection count haven't exceeded maxSelectionBoxCount).",
  },
  {
    name: ".ms-selectionbox-text",
    description: "Text inside the selection boxes.",
  },
  {
    name: ".ms-selectionbox-button",
    description: "Clear button on selection boxes.",
  },
  {
    name: ".ms-input-button-container",
    description: "Container that encapsulates input and open dropdown button.",
  },
  { name: ".ms-input", description: "Input inside the multiselect." },
  {
    name: ".ms-spinning-loader",
    description: "Spinning loader next to open dropdown button.",
  },
  { name: ".ms-toggle-popover-button", description: "Open dropdown button." },
  {
    name: ".ms-popover",
    description: "Popover that opens below the component.",
  },
  { name: ".ms-listitem", description: "List items inside popover." },
  {
    name: ".ms-empty-popover-text",
    description: "Popover loading or noDataFound text.",
  },
  {
    name: ".ms-linear-progressbar",
    description: "Loading indicator at the bottom of popover.",
  },
  {
    name: ".ms-listitem-checkbox",
    description:
      "Useful if default render option is rendered. Checkbox on the list items inside the popover.",
  },
];
