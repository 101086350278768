import clsx from "clsx";
import { NavBarItem } from "../Header";
import { ChevronDown, ChevronUp } from "lucide-react";
import { NavItemSubMenu } from "./NavItemSubMenu";
import { Link } from "react-router-dom";

type NavItemProps = {
  className?: string;
  closeDrawer: () => void;
  navItem: NavBarItem;
  onClick: (() => void) | undefined;
  isExpanded: boolean;
};

export function NavItem(props: NavItemProps) {
  const { className, closeDrawer, navItem, onClick, isExpanded } = props;

  return (
    <div className={clsx("flex flex-col group/navItem text-cosmos", className)}>
      <div
        className={clsx([
          {
            "hover:bg-cosmos/20 w-full h-14 flex flex-col cursor-pointer content-center font-bold":
              true,
          },
        ])}
      >
        {!navItem.hasSubMenu && (
          <Link
            className="h-full w-full flex items-center justify-start pl-4"
            onClick={() => closeDrawer()}
            to={navItem.menuItem.link ?? ""}
            state={{ from: location.pathname }}
          >
            <span className="flex gap-1 w-max place-items-center font-bold text-xl group-hover/link:h-64 transition-all duration-500 ease-out">
              {navItem.menuItem.text}
            </span>
          </Link>
        )}
        {navItem.hasSubMenu && (
          <div
            className={clsx([
              {
                "flex h-full w-full px-4 text-xl": true,
                "border-b-2": isExpanded,
              },
            ])}
            onClick={() => onClick?.()}
          >
            <span className="flex h-full w-full items-center justify-between">
              {navItem.menuItem.text}
              {!isExpanded && (
                <ChevronDown className="transition pc:group-hover/navItem:rotate-180 delay-150 duration-300 fade-in-20" />
              )}
              {isExpanded && <ChevronUp />}
            </span>
          </div>
        )}
      </div>
      {navItem.hasSubMenu && !!navItem.subMenuItems?.length && (
        <NavItemSubMenu
          closeDrawer={closeDrawer}
          subMenuItems={navItem.subMenuItems}
          isExpanded={isExpanded}
        />
      )}
    </div>
  );
}
