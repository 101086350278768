import { useState } from "react";
import { useThrottle } from "../../../utils/useThrottle";
import { useThrottleExampleCatLorem } from "./debounceAndThrottleData";

export default function WorkingThrottleExample() {
  const [throttledScrollPosition, setThrottledScrollPosition] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const throttle = useThrottle({ duration: 500 });

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const currentScrollPosition = e.currentTarget?.scrollTop;
    setScrollPosition(currentScrollPosition);

    throttle(() => {
      setThrottledScrollPosition(currentScrollPosition);
    });
  };

  return (
    <div className="flex flex-col gap-4 w-full items-center">
      <div className="flex gap-4 phone:flex-col tablet:flex-col">
        <div className="flex gap-2 border-2 border-taupe/40 box-shadow shadow-taupe/40 rounded-md p-2 phone:w-full tablet:w-full ">
          <p className="font-extrabold whitespace-nowrap text-taupe/60">
            Scroll Position:
          </p>
          <p className="text-cosmos/70 font-semibold whitespace-normal">
            {scrollPosition.toFixed(0)}
          </p>
        </div>
        <div className="flex gap-2 border-2 border-taupe/40 box-shadow shadow-taupe/40 rounded-md p-2 phone:w-full tablet:w-full ">
          <p className="font-extrabold whitespace-nowrap text-taupe/60">
            Throttled Scroll Position:
          </p>
          <p className="text-cosmos/70 font-semibold whitespace-normal">
            {throttledScrollPosition.toFixed(0)}
          </p>
        </div>
      </div>
      <div
        className="text-cosmos pc:w-1/2 pc:h-96 w-full h-36 overflow-x-hidden overflow-y-scroll border-2 border-taupe/40 box-shadow shadow-taupe/40 rounded-md p-2"
        onScroll={handleScroll}
      >
        {useThrottleExampleCatLorem}
      </div>
    </div>
  );
}
