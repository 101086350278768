import Answers from "./Answers";
import NavButton from "./NavButton";
import { quizData } from "../quizData";
import Explanation from "./Explanation";
import CodeBlock from "../../../../components/Components/CodeBlock/CodeBlock";
import { Choice, QuizAction, QuizActionKind, QuizState } from "../quizReducer";

type Props = {
  state: QuizState;
  dispatch: React.Dispatch<QuizAction>;
};

export default function QuestionView(props: Props) {
  const { state, dispatch } = props;
  const incrementPage = () => {
    if (state.page < 10) {
      dispatch({ type: QuizActionKind.INCREASEPAGE });
    }
  };

  const decrementPage = () => {
    if (state.page > 1) {
      dispatch({ type: QuizActionKind.DECREASEPAGE });
    }
  };

  const answerQuestion = (answer: Choice) => {
    dispatch({
      type: QuizActionKind.ANSWER,
      payload: answer,
    });
  };

  const endGame = () => {
    dispatch({ type: QuizActionKind.END });
  };

  return (
    <div className="flex flex-col h-full w-full justify-center pc:px-20">
      <div className="flex phone:flex-col tablet:flex-col justify-between gap-4">
        <NavButton
          className="phone:hidden tablet:hidden"
          onClick={decrementPage}
          disabled={state.page <= 1}
          iconClassName="-rotate-90"
        />
        <div className="flex pc:w-full justify-between items-center pc:hidden">
          <NavButton
            onClick={decrementPage}
            disabled={state.page <= 1}
            iconClassName="-rotate-90"
          />
          <NavButton
            className="pc:hidden"
            onClick={state.page === quizData.length ? endGame : incrementPage}
            disabled={state.page > quizData.length}
            iconClassName="rotate-90"
          />
        </div>
        <div className="w-full h-full flex flex-col gap-4">
          <div className="flex rounded-md h-10 w-full bg-marigold/30 text-taupe text-xl font-bold items-center justify-center shadow-md">
            <p>
              Question: {state.page} / {quizData.length}
            </p>
          </div>
          <div>
            <CodeBlock
              key={"Codeblock-" + (state.page - 1)}
              language="javascript"
              code={quizData[state.page - 1].questionCode}
            />
          </div>
          <Answers
            answerChoices={quizData[state.page - 1].answerChoices}
            answerQuestion={answerQuestion}
            correctAnswer={quizData[state.page - 1].correctAnswer}
            userAnswer={state.answers[state.page - 1]}
          />
        </div>
        <NavButton
          className="phone:hidden tablet:hidden"
          onClick={state.page === quizData.length ? endGame : incrementPage}
          disabled={state.page > quizData.length}
          iconClassName="rotate-90"
        />
      </div>
      <Explanation
        key={state.page}
        explanation={quizData[state.page - 1].explanation}
      />
    </div>
  );
}
