export default function Documentations() {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center gap-8">
      <div
        className="w-full flex text-center"
        style={{ background: "#e5b803" }}
      >
        <div className="pc:striped-div skew-x-12 w-1/4 phone:small-striped-div" />
        <p className="text-black font-extrabold text-2xl w-1/2 phone:text-sm tablet:text-sm break-none">
          ! UNDER CONSTRUCTION !
        </p>
        <div className="pc:striped-div skew-x-12 w-1/4 phone:small-striped-div" />
      </div>
      <img
        src={require("../assets/under-construction-panda.jpeg")}
        alt="Avatar of Me!"
        className={"rounded-full aspect-square w-full max-w-96 p-4"}
      />
    </div>
  );
}
