import CodeBlock from "../../../components/Components/CodeBlock/CodeBlock";
import SectionHeader from "../../../components/SectionHeader";
import { multiselectCode } from "./multiselectData";

export default function Usage() {
  return (
    <>
      <SectionHeader
        title="Usage"
        text={
          "Below you can see an example made by using open source RickAndMortyAPI."
        }
      />
      <div className="flex gap-1 items-center">
        <p className="text-sm font-semibold text-cosmos">
          (You can check this link to know more about the API:
        </p>
        <a
          className="text-taupe hover:text-iris underline"
          href="https://rickandmortyapi.com/documentation/"
        >
          RickAndMortyAPI
        </a>
        <p className="text-sm font-semibold text-cosmos">)</p>
      </div>
      <CodeBlock language="tsx" code={multiselectCode} />
    </>
  );
}
