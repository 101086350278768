import { Link, useLocation } from "react-router-dom";
import { NavBarItem } from "../Header";
import clsx from "clsx";

type Props = {
  subMenuItems: NavBarItem[];
};

export function ComponentsNav(props: Props) {
  const { subMenuItems } = props;
  const location = useLocation();

  return (
    <aside className="text-cosmos phone:hidden tablet:hidden scrollbar fixed bg-daisy overflow-y-auto flex flex-col h-full w-[200px] border-t-4 border-lily font-bold text-center">
      {subMenuItems.map((item, id) => (
        <Link
          key={id}
          to={item.menuItem.link ?? ""}
          className={clsx([
            {
              "flex border-b-4 border-lily px-2 text-lg font-base text-cosmos/80 hover:bg-marigold h-16 items-center justify-center":
                true,
              "bg-marigold": item.menuItem.link
                ? location.pathname.includes(item.menuItem.link)
                : false,
            },
          ])}
        >
          {item.menuItem.text}
        </Link>
      ))}
    </aside>
  );
}
