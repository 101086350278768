import { PropTableData } from "../../../components/DisplayTable";

export const intersectionObserverCode = `
  function doSomethingWhenIntersecting() {
    // doing something
  }

  const ref = useIntersectionObserver(doSomethingWhenIntersecting);

  return(
    <div ref={ref}/> //Then just give it as a ref to what you want to observe
  );`;

export const intersectionObserverProps: PropTableData[] = [
  {
    name: "fn",
    type: "() => Promise<void>",
    default: "-",
    description:
      "The function of what you want to do when intersection observed.",
  },
];

export const intersectionObserverReturns: PropTableData[] = [
  {
    name: "ref",
    type: " React.MutableRefObject<null>",
    default: "null",
    description: "Reference of the observed element.",
  },
];
