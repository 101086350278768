import DisplayTable from "../../../components/DisplayTable";
import { multiselectClasses, multiselectProps } from "./multiselectData";

export default function PropsAndClasses() {
  return (
    <div>
      <DisplayTable propDataList={multiselectProps} title="Props" />
      <DisplayTable
        propDataList={multiselectClasses}
        type="other"
        title="Class Names"
        text="Below are the class names for the components I used, allowing you to customize your multiselect from a higher level:"
      />
    </div>
  );
}
