import { useCombobox, useMultipleSelection } from "downshift";
import React, { useCallback } from "react";
import { Popover } from "./Popover";
import { SelectionBox } from "./SelectionBox";
import { InputWithButton } from "./InputWithButton";
import { useDebouncedInput } from "../../../utils/useDebouncedInput";
import {
  PaginatedFetchResult,
  usePaginatedFetch,
} from "../../../utils/usePaginatedFetch";
import clsx from "clsx";

type MultiSelectProps<T> = {
  className?: string;
  clearOnSelect?: boolean;
  clearSelectedOnBackspace?: boolean;
  disabled?: boolean;
  getOptionsFn: (
    searchInput: string,
    page: number,
    controller: AbortController
  ) => Promise<PaginatedFetchResult<T>>;
  highlightSearchText?: boolean;
  highlightFirstItem?: boolean;
  loadingText?: string;
  maxSelectionBoxCount?: number;
  noDataText?: string;
  onChange?: (selectedOptions: T[]) => void;
  openDropdownIcon?: JSX.Element;
  openOnFocus?: boolean;
  optionToKey: (option: T) => string;
  optionToString: (option: T) => string;
  placeHolderText?: string;
  renderOption?: (
    option: T,
    isSelected: boolean,
    inputText: string
  ) => JSX.Element;
};

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    className,
    clearSelectedOnBackspace = true,
    clearOnSelect = true,
    disabled = false,
    getOptionsFn,
    highlightSearchText = true,
    highlightFirstItem = true,
    loadingText = "Loading...",
    maxSelectionBoxCount = 2,
    noDataText = "No Data Found",
    onChange,
    openDropdownIcon,
    openOnFocus = true,
    optionToKey,
    optionToString,
    placeHolderText = "",
    renderOption,
  } = props;

  const [inputValue, setInputValue] = React.useState(""); //Textfield input value

  const [selectedItems, setSelectedItems] = React.useState<T[]>([]); //Selected values by user

  const debouncedInputValue = useDebouncedInput(inputValue); //Debounced input value to prevent continuous backend call

  //To keep users state same with multiselect
  const handleChange = (selectedItems: T[]) => {
    setSelectedItems(selectedItems);
    onChange?.(selectedItems);
  };

  //Paginated Infinite Fetch Hook
  const {
    isLoading,
    results: items,
    fetchNextPage,
  } = usePaginatedFetch({
    fetchQuery: useCallback(
      (page: number, controller: AbortController) =>
        getOptionsFn(debouncedInputValue, page, controller),
      [debouncedInputValue]
    ),
    dependencies: [debouncedInputValue],
  });

  //Downshift useMultipleSelection hook for accesibility with selection-boxes (Selection tags on input)
  const {
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    getSelectedItemProps,
  } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
          handleChange(newSelectedItems ?? []);
          break;
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          if (clearSelectedOnBackspace) {
            handleChange(newSelectedItems ?? []);
          }
          break;
        default:
          break;
      }
    },
  });

  //Downshift useCombobox hook for accesibility with combobox (Dropdown items)
  const comboboxProps = useCombobox({
    items: items ?? [],
    defaultHighlightedIndex: 0, // Highlights the first item.
    inputValue,
    selectedItem: null,
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            //Selected item add logic
            if (!selectedItems.includes(newSelectedItem)) {
              addSelectedItem(newSelectedItem);
              if (clearOnSelect) {
                setInputValue("");
              }
            } else {
              //Selected item remove logic
              const indexOfUnselectedItem = selectedItems.findIndex(
                (selectedItem) => selectedItem === newSelectedItem
              );

              const newItemsAfterUnselect = [...selectedItems];
              newItemsAfterUnselect.splice(indexOfUnselectedItem, 1);

              removeSelectedItem(newSelectedItem);

              if (clearOnSelect) {
                setInputValue("");
              }
            }
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue ?? "");

          break;
        default:
          break;
      }
    },
  });

  return (
    <div
      className={clsx("w-[500px] h-10", "ms-root", className)}
      {...getDropdownProps({ preventKeyAction: false })}
    >
      <div
        className={clsx(
          [
            {
              "text-slate-700 w-full bg-white shadow-md gap-2 items-center flex flex-nowrap py-2 px-1 border-2 border-orange-200 rounded-xl h-full mb-3 overflow-hidden":
                true,
              "opacity-80 bg-slate-100 border-slate-300": disabled,
            },
          ],
          "ms-input-container"
        )}
      >
        {selectedItems.map((selectedItemForRender, index) => {
          //Show only maxSelectionBoxCount selection boxes
          if (index < maxSelectionBoxCount) {
            return (
              <SelectionBox
                optionToString={optionToString}
                key={`selected-item-${index}`}
                disabled={disabled}
                getSelectedItemProps={getSelectedItemProps}
                index={index}
                removeSelectedItem={removeSelectedItem}
                selectedItemForRender={selectedItemForRender}
              />
            );
          }
        })}
        {
          //To show how many other selections exist
          selectedItems.length > maxSelectionBoxCount && (
            <p
              className={clsx(
                "text-nowrap font-bold text-slate-700",
                "ms-selection-overflow-text"
              )}
            >
              + {selectedItems.length - maxSelectionBoxCount}
            </p>
          )
        }
        <InputWithButton
          comboboxProps={comboboxProps}
          disabled={disabled}
          isLoading={isLoading}
          openDropdownIcon={openDropdownIcon}
          openOnFocus={openOnFocus}
          placeHolderText={placeHolderText}
        />
      </div>
      <Popover
        comboboxProps={comboboxProps}
        fetchNextPage={fetchNextPage}
        highlightFirstItem={highlightFirstItem}
        highlightSearchText={highlightSearchText}
        inputValue={debouncedInputValue}
        isLoading={isLoading}
        items={items ?? []}
        loadingText={loadingText}
        noDataText={noDataText}
        optionToKey={optionToKey}
        optionToString={optionToString}
        renderOption={renderOption}
        selectedItems={selectedItems}
      />
    </div>
  );
}
