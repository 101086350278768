import { AppLayout } from "./views/AppLayout";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { AboutMe } from "./views/AboutMe";
import { MultiselectAutocomplete } from "./views/Components/MultiselectAutocomplete/MultiselectAutocomplete";
import { Toast } from "./views/Components/Toast/Toast";
import { ContactMe } from "./views/ContactMe";
import { PageLayoutWithNav } from "./views/PageLayoutWithNav";
import {
  componentsSubMenuItems,
  playgroundSubMenuItems,
  utilsSubMenuItems,
} from "./components/Header";
import PaginatedFetch from "./views/Utils/PaginatedFetch/PaginatedFetch";
import IntersectionObserver from "./views/Utils/IntersectionObserver/IntersectionObserver";
import DebounceAndThrottle from "./views/Utils/DebounceAndThrottle/DebounceAndThrottle";
import CodeBlock from "./views/Components/CodeBlock/CodeBlock";
import FunArt from "./views/Playground/FunArt/FunArt";
import Quiz from "./views/Playground/Quiz/Quiz";
import Documentations from "./views/Documentations";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/about-me",
        element: <AboutMe />,
      },
      {
        path: "/burc-ui/components",
        element: <PageLayoutWithNav subMenuItems={componentsSubMenuItems} />,
        children: [
          {
            index: true,
            element: <Navigate to={"/burc-ui/components/multiselect"} />,
          },
          {
            path: "/burc-ui/components/multiselect",
            element: <MultiselectAutocomplete />,
          },
          {
            path: "/burc-ui/components/toast",
            element: <Toast />,
          },
          {
            path: "/burc-ui/components/code-block",
            element: <CodeBlock />,
          },
        ],
      },
      {
        path: "/burc-ui/utils",
        element: <PageLayoutWithNav subMenuItems={utilsSubMenuItems} />,
        children: [
          {
            index: true,
            element: <Navigate to={"/burc-ui/utils/paginated-fetch"} />,
          },
          {
            path: "/burc-ui/utils/paginated-fetch",
            element: <PaginatedFetch />,
          },
          {
            path: "/burc-ui/utils/intersection-observer",
            element: <IntersectionObserver />,
          },
          {
            path: "/burc-ui/utils/debounce-and-throttle",
            element: <DebounceAndThrottle />,
          },
        ],
      },
      {
        path: "/burc-ui/playground",
        element: <PageLayoutWithNav subMenuItems={playgroundSubMenuItems} />,
        children: [
          {
            index: true,
            element: <Navigate to={"/burc-ui/playground/fun-art"} />,
          },
          {
            path: "/burc-ui/playground/fun-art",
            element: <FunArt />,
          },
          {
            path: "/burc-ui/playground/quiz",
            element: <Quiz />,
          },
        ],
      },
      {
        path: "/contact-me",
        element: <ContactMe />,
      },
      {
        path: "/documentations",
        element: <Documentations />,
      },
    ],
  },
]);

function App() {
  return (
    <div className={"app"}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
