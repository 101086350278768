import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { NavBarItem } from "../Header";
import clsx from "clsx";
import { NavItem } from "./NavItem";
import Button from "../Button";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  navItems: NavBarItem[];
};

export function HamburgerDrawer(props: Props) {
  const { className, active, setActive, navItems } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  const navigate = useNavigate();

  const expandNavItem = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const closeDrawer = () => {
    console.log("test");
    setIsVisible(false);
    setTimeout(() => {
      console.log("asdkas");
      setActive(false);
    }, 300);
  };

  useEffect(() => {
    if (active) {
      setIsVisible(true);
    }
  }, [active]);

  if (!active) return null;

  return ReactDom.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      data-visible={isVisible ? "true" : "false"}
      onClick={closeDrawer}
      className={clsx(
        "fixed left-0 group top-0 z-[9999] flex h-[100dvh] data-[visible=true]:opacity-100 data-[visible=true]:visible data-[visible=false]:opacity-0 data-[visible=false]:invisible w-screen items-start justify-start bg-lily/50 transition-all duration-300",
        className
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative pt-28 z-10 h-full w-[250px] group-data-[visible=true]:translate-x-[calc(100dvw-250px)] group-data-[visible=false]:translate-x-[100dvw] border-2 border-cosmos bg-daisy transition-transform duration-300"
      >
        <div className="absolute top-14">
          <button
            className="aspect-square bg-daffodil rounded-full h-full"
            onClick={() => {
              closeDrawer();
              navigate("/", { state: { from: location.pathname } });
            }}
          >
            <X className="w-full text-cosmos" />
          </button>
        </div>
        {navItems.map((navItem, index) => {
          return (
            <NavItem
              className={clsx([
                {
                  "hover:bg-cosmos/20": !navItem.hasSubMenu,
                  "border-t-2 border-cosmos last:border-b-2": true,
                },
              ])}
              closeDrawer={closeDrawer}
              onClick={
                navItem.hasSubMenu ? () => expandNavItem(index) : undefined
              }
              isExpanded={expandedIndex === index}
              key={navItem.menuItem.text}
              navItem={navItem}
            />
          );
        })}
      </div>
    </div>,
    document.getElementById("drawer") as HTMLElement
  );
}
