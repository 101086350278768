import { Link, useLocation } from "react-router-dom";
import { NavBarItem } from "../Header";
import clsx from "clsx";
import { ChevronRight, X } from "lucide-react";
import { useState } from "react";

type Props = {
  subMenuItems: NavBarItem[];
};

export function ComponentsNav(props: Props) {
  const { subMenuItems } = props;

  const [isExpanded, setExpanded] = useState(true);
  const location = useLocation();

  return (
    <aside className="text-cosmos pc:hidden scrollbar px-4 py-2 fixed bottom-2 overflow-x-auto overflow-y-hidden flex gap-4 items-center w-full font-bold z-[999]">
      <button
        className="z-40 flex justify-center items-center bg-daisy h-12 aspect-square rounded-full shadow-md"
        onClick={() => setExpanded((prev) => !prev)}
      >
        {isExpanded ? <X /> : <ChevronRight />}
      </button>
      <div
        className={clsx([
          {
            "flex gap-4 justify-between z-30": true,
            "animate-in slide-in-from-left": isExpanded,
            "animate-out slide-out-to-left hidden": !isExpanded,
          },
        ])}
      >
        {subMenuItems.map((item, id) => (
          <Link
            key={id}
            to={item.menuItem.link ?? ""}
            className={clsx([
              {
                "shadow-md block text-center text text-lg px-4 py-2 h-12 rounded-full bg-daisy font-base text-cosmos/80 hover:bg-marigold whitespace-nowrap":
                  true,
                "bg-marigold": item.menuItem.link
                  ? location.pathname.includes(item.menuItem.link)
                  : false,
              },
            ])}
          >
            {item.menuItem.text}
          </Link>
        ))}
      </div>
    </aside>
  );
}
