import { Menu, Moon, Sun, X } from "lucide-react";
import { useState } from "react";
import { NavBar } from "./PCNavigationItems/NavBar";
import Button from "./Button";
import clsx from "clsx";
import { HamburgerDrawer } from "./MobileNavigationItems/HamburgerDrawer";
import { ThemeOptions } from "../views/AppLayout";
import { useLocation, useNavigate } from "react-router-dom";

export type NavData = {
  text: string;
  link?: string;
};

export type NavBarItem = {
  menuItem: NavData;
  hasSubMenu?: boolean;
  subMenuItems?: NavBarItem[];
};

export const componentsSubMenuItems: NavBarItem[] = [
  {
    menuItem: {
      text: "Multiselect",
      link: "/burc-ui/components/multiselect",
    },
  },
  {
    menuItem: { text: "Toast", link: "/burc-ui/components/toast" },
  },
  {
    menuItem: { text: "Code Block", link: "/burc-ui/components/code-block" },
  },
];

export const utilsSubMenuItems: NavBarItem[] = [
  {
    menuItem: {
      text: "Paginated Fetch",
      link: "/burc-ui/utils/paginated-fetch",
    },
  },
  {
    menuItem: {
      text: "Intersection Observer",
      link: "/burc-ui/utils/intersection-observer",
    },
  },
  {
    menuItem: {
      text: "Debounce & Throttle",
      link: "/burc-ui/utils/debounce-and-throttle",
    },
  },
];

export const playgroundSubMenuItems: NavBarItem[] = [
  {
    menuItem: {
      text: "Fun Art",
      link: "/burc-ui/playground/fun-art",
    },
  },
  {
    menuItem: { text: "Quiz", link: "/burc-ui/playground/quiz" },
  },
];

const navItems: NavBarItem[] = [
  {
    menuItem: {
      text: "About Me",
      link: "/about-me",
    },
  },
  {
    menuItem: {
      text: "Burc-ui",
      link: "/burc-ui",
    },
    hasSubMenu: true,
    subMenuItems: [
      {
        menuItem: {
          text: "Components",
          link: "/burc-ui/components",
        },
        hasSubMenu: true,
        subMenuItems: componentsSubMenuItems,
      },
      {
        menuItem: {
          text: "Utils",
          link: "/burc-ui/utils",
        },
        hasSubMenu: true,
        subMenuItems: utilsSubMenuItems,
      },
      {
        menuItem: {
          text: "Playground",
          link: "/burc-ui/playground",
        },
        hasSubMenu: true,
        subMenuItems: playgroundSubMenuItems,
      },
    ],
  },
  {
    menuItem: {
      text: "Contact Me",
      link: "/contact-me",
    },
  },
  {
    menuItem: {
      text: "Documentations",
      link: "/documentations",
    },
  },
];

type HeaderProps = {
  changeTheme: () => void;
  theme: ThemeOptions;
};

export function Header(props: HeaderProps) {
  const { changeTheme, theme } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const hamburgerClick = () => {
    setHamburgerOpen(true);
  };

  return (
    <>
      <header
        className={clsx(
          "app-header",
          "flex items-center phone:hidden tablet:hidden",
          theme
        )}
      >
        <div className="flex w-full h-12 mr-[1vw]">
          <Button
            className={"aspect-square bg-daffodil rounded-full "}
            onClick={changeTheme}
          >
            {theme === "light" ? (
              <Sun className="w-full text-cosmos" />
            ) : (
              <Moon className="w-full text-cosmos" />
            )}
          </Button>
        </div>
        <div className="flex justify-end gap-2">
          <NavBar navItems={navItems} />
          <Button
            className="h-12 aspect-square bg-daffodil rounded-full"
            onClick={() => {
              if (!isLandingPage) {
                navigate("/", { state: { from: location.pathname } });
              }
            }}
          >
            {isLandingPage ? (
              <Menu className="w-full text-cosmos" />
            ) : (
              <X className="w-full text-cosmos" />
            )}
          </Button>
        </div>
      </header>
      <header
        className={clsx("app-header", "flex items-center pc:hidden", theme)}
      >
        <div className="flex w-full h-12 mr-[1vw]">
          <Button
            className={"aspect-square bg-daffodil rounded-full "}
            onClick={changeTheme}
          >
            {theme === "light" ? (
              <Sun className="w-full text-cosmos" />
            ) : (
              <Moon className="w-full text-cosmos" />
            )}
          </Button>
        </div>
        {!hamburgerOpen && (
          <Button
            className="h-12 aspect-square bg-daffodil rounded-full"
            onClick={hamburgerClick}
          >
            <Menu className="w-full text-cosmos" />
          </Button>
        )}
        <HamburgerDrawer
          className={theme}
          setActive={setHamburgerOpen}
          active={hamburgerOpen}
          navItems={navItems}
        />
      </header>
    </>
  );
}
