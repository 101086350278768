import { useEffect, useRef } from "react";
import { Toast } from "./Toast";
import clsx from "clsx";

export type ToastPosition =
  | "top-center"
  | "top-right"
  | "top-left"
  | "bottom-center"
  | "bottom-right"
  | "bottom-left";

export type ToastListProps = {
  toasts: Toast[];
  position: ToastPosition;
  removeToast: (id: number) => void;
};

const toastPositionClassMap: Record<ToastPosition, string> = {
  "top-center": "my-4 top-0 left-1/2 -translate-x-1/2 items-center",
  "top-left": "m-4 top-0 left-0 items-start",
  "top-right": "m-4 top-0 right-0 items-end",
  "bottom-center": "my-4 bottom-0 left-1/2 -translate-x-1/2 items-center",
  "bottom-left": "m-4 bottom-0 left-0 items-start",
  "bottom-right": "m-4 bottom-0 right-0 items-end",
};

export function ToastList(props: ToastListProps) {
  const { toasts, removeToast, position, ...rest } = props;

  const lastItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastItemRef.current) {
      lastItemRef.current.scrollIntoView();
    }
  }, [toasts]);

  function checkLastItem(index: number) {
    if (position.includes("top")) {
      return index === 0;
    }

    return index === toasts.length - 1;
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-2 absolute z-[9999] overflow-y-scroll max-h-[calc(100vh-2rem)] " +
          toastPositionClassMap[position as ToastPosition],
        "no-scrollbar"
      )}
      aria-live="assertive"
    >
      {toasts.map((toast, index) => (
        <Toast
          ref={checkLastItem(index) ? lastItemRef : null}
          {...rest}
          key={
            toast.id.toString() +
            toast.message?.slice(0, 2) +
            toast.title?.slice(0, 2)
          }
          removeToast={() => removeToast(toast.id)}
          toast={toast}
        />
      ))}
    </div>
  );
}
