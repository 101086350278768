import clsx from "clsx";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  value?: string | number | readonly string[];
};

export default function Button({
  children,
  onClick,
  className,
  type,
  value,
}: Props) {
  return (
    <button
      role="button"
      value={value}
      type={type}
      aria-label="Click to perform an action"
      onClick={onClick}
      className={clsx(
        " flex justify-center items-center cursor-pointer border-2 border-cosmos font-bold box-shadow shadow-cosmos transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none  p-2 ",
        className
      )}
    >
      {children}
    </button>
  );
}
