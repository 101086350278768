import { useState } from "react";
import { Toast, ToastOptions } from "./Toast";

export function useToastManager(autoHide?: boolean, autoHideDuration?: number) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const preferredAutoHide = autoHide ?? true;
  const prefferedAutoHideDuration = autoHideDuration ?? 5000;

  const showToast = (options: ToastOptions) => {
    const toast: Toast = {
      id: Date.now(),
      className: options.className,
      message: options.message,
      Action: options.Action,
      type: options.type,
      Icon: options.Icon,
      position: options.position ?? "top-right",
      title: options.title,
    };

    setToasts((prevToasts: Toast[]) => [...prevToasts, toast]);

    if (preferredAutoHide) {
      //Auto-remove of toasts after autoHideDuration ends
      setTimeout(() => {
        removeToast(toast.id);
      }, prefferedAutoHideDuration);
    }
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return {
    toasts,
    showToast,
    removeToast,
  };
}
