import { ToastOptions } from "../../../components/Components/Toast/Toast";

export const successToasts: ToastOptions[] = [
  {
    title: "Hooray!",
    message: "Mission accomplished! You've nailed it.",
    type: "success",
  },
  {
    title: "Success!",
    message: "You did it! The code gods are smiling down on you.",
    type: "success",
  },
  {
    title: "Victory Dance!",
    message: "High-five! Everything worked perfectly.",
    type: "success",
  },
];

export const warningToasts: ToastOptions[] = [
  {
    title: "Heads Up!",
    message: "Proceed with caution! Things might get a little bumpy.",
    type: "warning",
  },
  {
    title: "Alert!",
    message: "Something’s brewing. Keep an eye on this.",
    type: "warning",
  },
  {
    title: "Careful!",
    message: "A small hiccup ahead. Tread lightly!",
    type: "warning",
  },
];

export const errorToasts: ToastOptions[] = [
  {
    title: "Oops!",
    message: "Looks like something went awry. Let’s fix it together!",
    type: "error",
  },
  {
    title: "Error Detected!",
    message: "Houston, we have a problem. Time to troubleshoot!",
    type: "error",
  },
  {
    title: "Whoopsie!",
    message: "Something went wrong. Don’t worry, we’ve got this!",
    type: "error",
  },
];

export const infoToasts: ToastOptions[] = [
  {
    title: "Heads Up!",
    message: "Just so you know, everything’s running smoothly. Carry on!",
    type: "info",
  },
  {
    title: "Did You Know?",
    message: "Our code is as neat as a cat’s whiskers today!",
    type: "info",
  },
  {
    title: "FYI!",
    message: "Here's a little nugget of info to brighten your day.",
    type: "info",
  },
];
