import { useState } from "react";
import Button from "../../../components/Button";
import { useToast } from "../../../components/Components/Toast/ToastProvider";
import {
  successToasts,
  errorToasts,
  warningToasts,
  infoToasts,
} from "./toastTexts";
import SectionHeader from "../../../components/SectionHeader";

export default function ToastTypes() {
  const { showToast } = useToast();

  const [successIndex, setSuccessIndex] = useState(0);
  const [warningIndex, setWarningIndex] = useState(0);
  const [errorIndex, setErrorIndex] = useState(0);
  const [infoIndex, setInfoIndex] = useState(0);

  return (
    <>
      <SectionHeader
        title="Preview Types"
        text="You can check different types of toasts here."
      />
      <div className="flex flex-col gap-8 bg-tulip/40 p-10 overflow-hidden w-full">
        <div className="bg-lily rounded-full w-48 h-48 relative -m-28 p-6">
          <div className="bg-tulip/40 rounded-full w-full h-full relative p-6">
            <div className="bg-lily rounded-full w-full h-full relative" />
          </div>
        </div>
        <div className="flex phone:flex-col tablet:flex-col gap-8 items-center justify-center z-10">
          <Button
            className="bg-tulip text-taupe shadow-taupe border-taupe h-12 min-w-44"
            onClick={() => {
              showToast({
                ...successToasts[successIndex % 3],
              });
              setSuccessIndex((prev) => prev + 1);
            }}
          >
            Spawn Success
          </Button>
          <Button
            className="bg-daffodil text-taupe shadow-taupe border-taupe h-12 min-w-44"
            onClick={() => {
              showToast({
                ...warningToasts[warningIndex % 3],
              });
              setWarningIndex((prev) => prev + 1);
            }}
          >
            Spawn Warning
          </Button>
          <Button
            className="bg-lilium text-taupe shadow-taupe border-taupe h-12 min-w-44"
            onClick={() => {
              showToast({
                ...errorToasts[errorIndex % 3],
              });
              setErrorIndex((prev) => prev + 1);
            }}
          >
            Spawn Error
          </Button>

          <Button
            className="bg-iris text-taupe shadow-taupe border-taupe h-12 min-w-44"
            onClick={() => {
              showToast({
                ...infoToasts[infoIndex % 3],
              });
              setInfoIndex((prev) => prev + 1);
            }}
          >
            Spawn Info
          </Button>
        </div>
      </div>
    </>
  );
}
