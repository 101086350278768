import { useState } from "react";
import { useToast } from "../../../components/Components/Toast/ToastProvider";
import {
  successToasts,
  errorToasts,
  warningToasts,
  infoToasts,
} from "./toastTexts";
import SectionHeader from "../../../components/SectionHeader";

const allToasts = [
  ...successToasts,
  ...errorToasts,
  ...warningToasts,
  ...infoToasts,
];

export default function ToastPositions() {
  const { showToast } = useToast();

  const [toastIndex, setToastIndex] = useState(0);
  return (
    <>
      <SectionHeader
        title="Preview Positions"
        text="Below you can check how toasts look on different positions."
      />
      <div className="flex items-center justify-center">
        <div className="box-shadow shadow-taupe border-2 border-taupe w-2/3 bg-daisy p-1 min-w-fit phone:text-xs tablet:text-small aspect-[3] pc:aspect-[4] whitespace-nowrap">
          <div className="grid grid-flow-row gap-2 h-full">
            <div className="grid grid-flow-col gap-2">
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "top-left",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                TOP-LEFT
              </button>
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "top-center",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                TOP-CENTER
              </button>
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "top-right",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                TOP-RIGHT
              </button>
            </div>
            <div className="grid grid-flow-col gap-2">
              <div></div>
            </div>
            <div className="grid grid-flow-col gap-2">
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "bottom-left",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                BOTTOM-LEFT
              </button>
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "bottom-center",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                BOTTOM-CENTER
              </button>
              <button
                className="text-taupe font-bold hover:text-taupe/50"
                onClick={() => {
                  showToast({
                    ...allToasts[toastIndex % 12],
                    position: "bottom-right",
                  });
                  setToastIndex((prev) => prev + 1);
                }}
              >
                BOTTOM-RIGHT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
