import { MultiSelect } from "../../../components/Components/Multiselect/Multiselect";
import { useToast } from "../../../components/Components/Toast/ToastProvider";
import SectionHeader from "../../../components/SectionHeader";
import { Option, searchByInput } from "./searchQuery";

const renderOption = (option: Option, isSelected: boolean) => {
  return (
    <>
      <input
        className="size-4 accent-amber-600"
        type="checkbox"
        checked={isSelected}
        readOnly
      />
      <img className="rounded-lg h-10" src={option.image} />
      <div className="flex flex-col">
        {option.name}
        <span className="text-sm text-slate-600 font-medium">
          {option.episode.length + " Episodes"}
        </span>
      </div>
    </>
  );
};

export default function WorkingExamples() {
  const { showToast } = useToast();

  const handleError = (errorMessage: string) => {
    showToast({ message: errorMessage, title: "Error", type: "error" });
  };

  return (
    <div className="flex flex-col gap-4">
      <SectionHeader
        title="Working code"
        text="Above code will render as below and next to it you can find a fancier example with renderOption prop:"
      />
      <div className="flex gap-4 phone:flex-col tablet:flex-col items-center justify-center">
        <MultiSelect
          className="w-1/3 phone:w-full tablet:w-full"
          optionToKey={(option: Option) => option.id.toString()}
          optionToString={(option) => option.name}
          getOptionsFn={(searchInput, page, controller) =>
            searchByInput(searchInput, page, controller, handleError)
          }
          placeHolderText="Search Ricks&Mortys!"
        />
        <MultiSelect
          className="w-1/3 phone:w-full tablet:w-full"
          optionToKey={(option) => option.id.toString()}
          optionToString={(option) => option.name}
          getOptionsFn={(searchInput, page, controller) =>
            searchByInput(searchInput, page, controller, handleError)
          }
          renderOption={renderOption}
          placeHolderText="Search Ricks&Mortys Fancier!"
        />
      </div>
    </div>
  );
}
