import { useReducer, useState } from "react";
import {
  QuizAction,
  QuizActionKind,
  QuizState,
  quizReducer,
} from "./quizReducer";
import QuestionView from "./QuestionView/QuestionView";
import Button from "../../../components/Button";
import Modal from "../../../components/Components/Modal/Modal";
import { quizData } from "./quizData";

type ModalContentProps = {
  state: QuizState;
  dispatch: React.Dispatch<QuizAction>;
  retryGame: () => void;
};

function ModalContent(props: ModalContentProps) {
  const { state, dispatch, retryGame } = props;

  if (state.isActive === true) {
    return <QuestionView state={state} dispatch={dispatch} />;
  }

  const calculateScoreText = () => {
    const correctAnswers = quizData.filter((question, index) => {
      return state.answers[index] === question.correctAnswer;
    });

    return "Score: " + correctAnswers.length + "/" + quizData.length;
  };

  if (state.isActive === false) {
    return (
      <div className="w-full min-h-96 flex items-center justify-center">
        <div className="text-cosmos flex flex-col gap-4 items-center justify-center box-shadow border-2 shadow-cosmos border-cosmos pc:w-1/2 p-4 w-full bg-daisy">
          <p className="text-xl font-bold">{calculateScoreText()}</p>
          <Button className="bg-marigold w-1/3" onClick={retryGame}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return <></>;
}

export default function Controller() {
  const [state, dispatch] = useReducer(quizReducer, {
    page: 1,
    isActive: false,
    answers: [],
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const startGame = () => {
    dispatch({ type: QuizActionKind.START });
    setModalOpen(true);
  };

  const endGame = () => {
    dispatch({ type: QuizActionKind.END });
    dispatch({ type: QuizActionKind.INITIALIZE });
    setModalOpen(false);
  };

  const retryGame = () => {
    dispatch({ type: QuizActionKind.END });
    dispatch({ type: QuizActionKind.INITIALIZE });
    dispatch({ type: QuizActionKind.START });
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col gap-4 items-center justify-center box-shadow border-2 shadow-cosmos border-cosmos pc:w-1/2 p-4 py-8 w-full bg-daisy h-full">
          <Button className="bg-marigold pc:w-1/3 w-full" onClick={startGame}>
            Start Game
          </Button>
        </div>
      </div>
      <Modal active={isModalOpen} onClose={endGame}>
        <ModalContent state={state} dispatch={dispatch} retryGame={retryGame} />
      </Modal>
    </>
  );
}
