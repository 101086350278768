import clsx from "clsx";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";

export default function Explanation({ explanation }: { explanation: string }) {
  const [isOpen, setOpen] = useState(false);

  const expandExplanation = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="w-full rounded-md pt-8 pc:px-12">
      <div
        className=" h-10 text-center pc:px-8 p-2 flex rounded-md min-h-10 w-full bg-iris/10 text-cosmos/70 text-lg tablet:text-md phone:text-md font-bold items-center justify-between shadow-md hover:brightness-75 cursor-pointer"
        onClick={expandExplanation}
      >
        <p>Explanation:</p>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      <div
        className={clsx(
          "slide-in-from-top-2 h-full transition shadow-lg p-2 rounded-b-md overflow-y-scroll mb-8",
          {
            ["animate-in fade-in-0 max-h-52 opacity-100"]: isOpen,
            ["animate-out fade-out-0 max-h-0 opacity-0"]: !isOpen,
          }
        )}
      >
        <div className="flex h-full">{explanation}</div>
      </div>
    </div>
  );
}
