import React from "react";

export function useDebouncedInput(input: string, timeout?: number) {
  const [debouncedInput, setDebouncedInput] = React.useState("");
  const preferedTimeout = timeout ?? 500;

  // To get and set debounced input, default 500ms
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (input !== debouncedInput) {
        setDebouncedInput(input);
      }
    }, preferedTimeout);

    return () => clearTimeout(timeoutId);
  }, [input, preferedTimeout]);

  return debouncedInput;
}
