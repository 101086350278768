export default function Ghost() {
  return (
    <div className="flex flex-row-reverse gap-2 items-center justify-center bg-black h-96 w-96 rounded-xl p-4">
      <div className="flex items-center opacity-95">
        <div className="w-5 overflow-hidden">
          <div className="h-7 bg-white transform rotate-45 origin-bottom-right rounded-sm"></div>
        </div>
        <div className="bg-white h-full p-2 w-20 rounded-xl flex items-center justify-center break-all">
          <p className="text-black font-bold text-2xl">
            BOOOOOOOOOOOOOOOOOOO!!!
          </p>
        </div>
      </div>
      <div
        className="relative rounded-t-full h-72 w-44 bg-white animate-ghostGlow"
        style={{
          filter: "drop-shadow(0 0 30px #fff)",
        }}
      >
        {/*Eyes Start */}
        <div className="absolute top-12 flex justify-around px-4 w-full">
          <div className="bg-black h-10 w-10 rounded-full p-4 animate-ghostBlink">
            <div className="bg-white h-4 w-4 rounded-full" />
          </div>
          <div className="bg-black h-10 w-10 rounded-full p-4 animate-ghostBlink">
            <div className="bg-white h-4 w-4 rounded-full" />
          </div>
        </div>
        {/*Eyes End */}
        {/*Blush Start */}
        <div className="absolute top-24 flex w-full justify-between px-4">
          <div className="bg-red-100 h-4 w-6 rounded-[50%]" />
          <div className="bg-red-100 h-4 w-6 rounded-[50%]" />
        </div>
        {/*Blush End */}
        {/*Mouth Start */}
        <div className="absolute top-28 left-[72px] bg-black h-11 w-8 rounded-full overflow-hidden">
          <div className="absolute bottom-0 right-0 rounded-full bg-red-900 h-6 w-6" />
        </div>
        {/*Mouth End */}
        <div className="w-44 h-6 overflow-hidden absolute bottom-0 translate-y-6 ">
          <div className="w-full flex justify-between animate-ghostFlow">
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
            <div className="bg-white h-6 min-w-11 rounded-b-full" />
          </div>
        </div>
      </div>
    </div>
  );
}
