import { UseComboboxReturnValue } from "downshift";
import { Loader2, Triangle } from "lucide-react";
import clsx from "clsx";

type InputWithButtonProps<T> = {
  comboboxProps: UseComboboxReturnValue<T>;
  disabled: boolean;
  isLoading: boolean;
  openDropdownIcon?: JSX.Element;
  openOnFocus: boolean;
  placeHolderText: string;
};

export function InputWithButton<T>(props: InputWithButtonProps<T>) {
  const {
    comboboxProps,
    disabled,
    isLoading,
    openDropdownIcon,
    openOnFocus,
    placeHolderText,
  } = props;

  const { getToggleButtonProps, getInputProps } = comboboxProps;

  return (
    <div className={clsx("flex gap-0.5 grow", "ms-input-button-container")}>
      <input
        disabled={disabled}
        placeholder={placeHolderText}
        className={clsx(
          "w-full focus-visible:outline-none placeholder-shown:truncate",
          "ms-input"
        )}
        {...getInputProps()}
        onClick={(e) => {
          if (openOnFocus && !disabled) {
            getInputProps().onClick(e);
          }
        }}
      />
      {isLoading && (
        <Loader2
          className={clsx("text-amber-500 animate-spin", "ms-spinning-loader")}
        />
      )}
      <button
        disabled={disabled}
        aria-label="toggle menu"
        className={clsx(
          "flex items-center justify-center px-2",
          "ms-toggle-popover-button"
        )}
        type="button"
        {...getToggleButtonProps()}
      >
        {openDropdownIcon ?? (
          <Triangle
            className={clsx([
              {
                "rotate-180 fill-amber-600": true,
                "fill-slate-600": disabled,
              },
            ])}
            strokeWidth={0}
            size={18}
          />
        )}
      </button>
    </div>
  );
}
