type Props = {
  className?: string;
  title: string;
  text?: string;
};

export default function SectionHeader(props: Props) {
  const { className, text, title } = props;
  return (
    <div className={className}>
      <p className="text-xl font-extrabold text-taupe opacity-60">{title}</p>
      <div className="w-full h-[2px] bg-taupe opacity-40" />
      {text && <p className="text-sm text-cosmos mt-2 font-semibold">{text}</p>}
    </div>
  );
}
