import { PropTableData } from "../../../components/DisplayTable";

export const toastProviderCode = `<ToastProvider defaultPosition="top-right" autoHideDuration={3000}>
  <App/>
<ToastProvider/>`;

export const providerProps: PropTableData[] = [
  {
    name: "defaultPosition",
    type: "ToastTypes: success | warning | error | info",
    default: "-",
    description:
      "Determines a default value for your toasts. If you show your toast only right-top side of your page, youcan set it here and no need to explicitly tell your toasts where to show up!",
  },
  {
    name: "autoHide",
    type: "boolean",
    default: "false",
    description: "Whether toasts will disappear on their own or not.",
  },
  {
    name: "autoHideDuration",
    type: "number",
    default: "5000",
    description: "Toast hide duration(ms).",
  },
];

export const showFunctionProps: PropTableData[] = [
  {
    name: "className",
    type: "string",
    default: "-",
    description: "Optional. You can give your tailwind or style classes.",
  },
  {
    name: "title",
    type: "string",
    default: "-",
    description:
      "Optional. Your custom title that will show up on top of toast.",
  },
  {
    name: "message",
    type: "string",
    default: "-",
    description: "Optional. Your custom message that will show up.",
  },
  {
    name: "type",
    type: "ToastTypes: success | warning | error | info",
    default: "-",
    description:
      "Type of your toast. Determines deafult icon and color of the toast.",
  },
  {
    name: "position",
    type: "ToastPositions: top-left | top-center | top-right | bottom-left | bottom-center | bottom-right",
    default: "-",
    description:
      "Position of your toast. Determines where your toast will show up.",
  },
  {
    name: "Action",
    type: "JSX.Element",
    default: "Close Button that removes the toast.",
    description:
      "You can add custom actions to your toasts. You can use 'removeToast' function from 'useToast' hook if you want to use on your custom actions.",
  },
  {
    name: "Icon",
    type: "JSX.Element",
    default: "Determined by type",
    description: "You can give custom icons.",
  },
];

export const useToastCode = `const ToastShowingComponent = () => {
  const { showToast } = useToast(); // You can also get "removeToast" function here for custom creation purposes.

  const handleClick = () => {
    showToast({
      title: "Wait!",
      message: "Important toast message.",
      type: "info",
    });
  };
  
  return(
    <button onClick={handleClick}>Show Toast</button>
  );
}`;
