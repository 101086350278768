import Controller from "./Controller";

export default function Quiz() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Javascript Quiz!!!
        </p>
        <p className="text-sm font-semibold text-cosmos">
          Welcome to my JavaScript Quiz! You&apos;ve explored my components and
          assessed my JS skills—now it&apos;s your turn to show off yours. Test
          your knowledge, learn new things, and have fun with the weird JS
          calculations. If you feel stuck you can always consult to good old
          browser console. Good luck, and enjoy the quiz!
        </p>
      </div>
      <Controller />
      <div />
    </div>
  );
}
