import CodeBlock from "../../../components/Components/CodeBlock/CodeBlock";
import DisplayTable from "../../../components/DisplayTable";
import SectionHeader from "../../../components/SectionHeader";
import {
  intersectionObserverCode,
  intersectionObserverProps,
  intersectionObserverReturns,
} from "./intersectionObserverData";

export default function IntersectionObserver() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">
          Intersection Observer
        </p>
        <p className="text-sm font-semibold text-cosmos">
          Do you need an intersection observer but don&apos;t want to use the
          one that everybody uses? Forget all the other libraries – this
          custom-made gem does the job with flair and efficiency. Whether
          it&apos;s for lazy-loading images or triggering cool effects as you
          scroll, this hook is just for you! :D
        </p>
      </div>
      <SectionHeader
        text="It's pretty straight forward actually:"
        title="How to Use"
      />
      <CodeBlock language="jsx" code={intersectionObserverCode} />
      <DisplayTable title="Props" propDataList={intersectionObserverProps} />
      <DisplayTable
        title="Returns"
        propDataList={intersectionObserverReturns}
      />
      <div />
    </div>
  );
}
