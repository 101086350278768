import Usage from "./Usage";
import ToastTypes from "./ToastTypes";
import ToastPositions from "./ToastPositions";

export function Toast() {
  return (
    <div className="flex py-8 flex-col w-full gap-4">
      <div className="h-full flex flex-col gap-4 mb-4">
        <p className="text-4xl font-extrabold text-taupe opacity-60">Toast</p>
        <p className="text-sm font-semibold text-cosmos">
          I’m a big fan of DIY projects, so when I needed a toast component for
          my autocomplete, I decided to skip the easy route of using a
          ready-made library. Instead, I took on the challenge and built my own!
          Please enjoy my custom-made component.
        </p>
      </div>
      <Usage />
      <ToastTypes />
      <ToastPositions />
    </div>
  );
}
