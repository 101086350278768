import clsx from "clsx";
import { Triangle } from "lucide-react";

type Props = {
  className?: string;
  disabled: boolean;
  onClick: () => void;
  iconClassName: string;
};

export default function NavButton(props: Props) {
  const { className, disabled, onClick, iconClassName } = props;

  return (
    <button
      className={clsx([
        {
          "w-12 flex items-center justify-center rounded-md border-2 shadow-sm":
            true,
          "bg-daisy hover:brightness-95 border-taupe/30": !disabled,
          "bg-cosmos/10 border-cosmos/20 cursor-default": disabled,
        },
        className,
      ])}
      onClick={onClick}
    >
      <Triangle
        className={clsx(
          [
            {
              "fill-taupe": true,
              "fill-cosmos/40": disabled,
            },
          ],
          iconClassName
        )}
        strokeWidth={0}
        size={24}
      />
    </button>
  );
}
