import { ToastList, ToastPosition } from "./ToastList";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { useToastManager } from "./useToastManager";
import { Toast, ToastOptions } from "./Toast";

type ToastContextType = {
  showToast: (options: ToastOptions) => void;
  removeToast: (id: number) => void;
};

const ToastContext = createContext<ToastContextType>({} as ToastContextType);

export const useToast = () => useContext(ToastContext);

export interface ToastProviderProps extends PropsWithChildren {
  defaultPosition: ToastPosition;
  autoHide?: boolean;
  autoHideDuration?: number;
}

export default function ToastProvider(props: ToastProviderProps) {
  const {
    children,
    autoHide,
    autoHideDuration,
    defaultPosition = "top-right",
  } = props;

  const { toasts, removeToast, showToast } = useToastManager(
    autoHide,
    autoHideDuration
  );

  const toastsByPositions: Partial<Record<ToastPosition, Toast[]>> = useMemo(
    () =>
      Object.groupBy(
        toasts,
        (toast: Toast) => toast.position ?? defaultPosition
      ),
    [toasts, defaultPosition]
  );

  const sortedToastsByPosition: Map<ToastPosition, Toast[]> = new Map();

  for (const positionKey in toastsByPositions) {
    const sortedToasts = positionKey.includes("top")
      ? toastsByPositions[positionKey as ToastPosition]!.reverse()
      : toastsByPositions[positionKey as ToastPosition]!;

    if (sortedToasts.length > 0) {
      sortedToastsByPosition.set(positionKey as ToastPosition, sortedToasts);
    }
  }

  return (
    <>
      <ToastContext.Provider value={{ showToast, removeToast }}>
        {children}
      </ToastContext.Provider>
      {Array.from(sortedToastsByPosition.keys()).map((position) => (
        <ToastList
          key={position}
          toasts={sortedToastsByPosition.get(position)!}
          removeToast={removeToast}
          position={position}
        />
      ))}
    </>
  );
}
